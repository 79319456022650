
import { UPLOAD_PHOTO } from "../fetch"
const root = "file"

// Update photo

export default {
    uploadPhoto : async function(photo,folder,token) {
        try {
            let response = UPLOAD_PHOTO(`/${root}/upload`,photo,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
}