import Vue from "vue";
import VueRouter from "vue-router";

import Dashboard from "@/pages/Dashboard/Dashboard";
import Admin from "@/pages/Admin";
import AdminAuth from "@/pages/Auth";
import AdminLogin from "@/views/Auth/Login";
import AdminList from "@/pages/Admins/AdminList";
import AdminAdd from "@/pages/Admins/AdminAdd";
import AdminEdit from "@/pages/Admins/AdminEdit";
import UserList from "@/pages/User/UserList";
import UsersReport from "@/pages/User/UsersReport";
import UserDetails from "@/pages/User/UserDetails";
import UserUpdate from "@/pages/User/UserUpdate"
import BirthdayCardsList from "@/pages/BirthdayCards/BirthdayCardsList";
import CnNewyearCardsList from "@/pages/CnNewyearCards/CnNewyearCardsList";
import CnNewyearCardsReport from "@/pages/CnNewyearCards/CnNewyearCardsReport"
import ItemList from "@/pages/Items/ItemList";
import ItemAdd from "@/pages/Items/ItemAdd";
import ItemEdit from "@/pages/Items/ItemEdit";
import DiscountList from "@/pages/Discounts/DiscountList";
import DiscountAdd from "@/pages/Discounts/DiscountAdd";
import DiscountEdit from "@/pages/Discounts/DiscountEdit";
import NewsList from "@/pages/News/NewsList";
import NewsAdd from "@/pages/News/NewsAdd";
import NewsEdit from "@/pages/News/NewsEdit";
import GoldPriceList from "@/pages/GoldPrice/GoldPriceList";
import GoldPriceAdd from "@/pages/GoldPrice/GoldPriceAdd";
import GoldPriceEdit from "@/pages/GoldPrice/GoldPriceEdit";
import GiftCardList from "@/pages/GiftCard/GiftCardList";
import GiftCardAdd from "@/pages/GiftCard/GiftCardAdd";
import GiftCardEdit from "@/pages/GiftCard/GiftCardEdit";
import PromotionList from "@/pages/Promotion/PromotionList";
import PromotionAdd from "@/pages/Promotion/PromotionAdd";
import PromotionEdit from "@/pages/Promotion/PromotionEdit";
import SettingList from "@/pages/Setting/SettingList";
import SettingAdd from "@/pages/Setting/SettingAdd";
import RequestList from "@/pages/Request/RequestList";
import PointRecordList from "@/pages/PointRecords/PointRecordList";
import PointRecordReport from "@/pages/PointRecords/PointRecordReport";
import BirthdayCardsReport from "@/pages/BirthdayCards/BirthdayCardsReport";
import RequestReport from "@/pages/Request/RequestReport"
import SendNotification from "@/pages/SendNotification/SendNotification"

import Auth from "@/api/model/Auth";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "အက်ဒမင်",
    component: Admin,
    beforeEnter: async (to, from, next) => await verifyAdminLogin(next),
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "ပင်မစာမျက်နှာ",
        component: Dashboard,
      },
      {
        path: "/point-records",
        name: "Goldပေးထားမှုများ",
        component: PointRecordList,
      },
      {
        path: "/point-record-report",
        name: "Gold ပေးထားမှုများ",
        component: PointRecordReport,
      },
      {
        path: "/birthday-cards",
        name: "မွေးနေ့လက်ဆောင် ပေးထားမှုများ",
        component: BirthdayCardsList,
      },
      {
        path: "/chinese-newyear-cards",
        name: "Seasonal Gifts ပေးထားမှုများ",
        component: CnNewyearCardsList
      },
      {
        path: "/cn-newyear-cards-report",
        name: "Seasonal Gifts ပေးထားမှုများ",
        component: CnNewyearCardsReport
      },
      {
        path: "/birthday-cards-report",
        name: "မွေနေ့လက်ဆောင် ပေးထားမှုများ",
        component: BirthdayCardsReport,
      },
      {
        path: "/admins",
        name: "အက်ဒမင်",
        component: AdminList,
      },
      {
        path: "/admin-add",
        name: "အက်ဒမင်အသစ် ထည့်ရန်",
        component: AdminAdd,
      },
      {
        path: "/admins/:adminId",
        name: "အက်ဒမင်ပြင်ဆင်ရန်",
        component: AdminEdit,
      },
      {
        path: "/users",
        name: "အသုံးပြုသူများ",
        component: UserList,
      },
      {
        path: "/users-report",
        name: "အသုံးပြုသူများ",
        component: UsersReport
      },
      {
        path: "/users/:userId",
        name: "အသုံးပြုသူ",
        component: UserDetails,
      },
      {
        path: "/users/:userId/update",
        name: "ဒေတာပြင်ရန်",
        component: UserUpdate
      },
      {
        path: "/items",
        name: "ပစ္စည်းများ",
        component: ItemList,
      },
      {
        path: "/item-add",
        name: "ပစ္စည်းအသစ်ထည့်ရန်",
        component: ItemAdd,
      },
      {
        path: "/items/:itemId",
        name: "ပစ္စည်းပြင်ဆင်ရန်",
        component: ItemEdit,
      },
      {
        path: "/discounts",
        name: "လျှော့ဈေးများ",
        component: DiscountList,
      },
      {
        path: "/discount-add",
        name: "လျှော့ဈေးအသစ်ထည့်ရန်",
        component: DiscountAdd,
      },
      {
        path: "/discount/:discountId",
        name: "လျှော့ဈေးပြင်ဆင်ရန်",
        component: DiscountEdit,
      },
      {
        path: "/news",
        name: "သတင်းများ",
        component: NewsList,
      },
      {
        path: "/news-add",
        name: "သတင်းအသစ်တင်ရန်",
        component: NewsAdd,
      },
      {
        path: "/news/:newsId",
        name: "သတင်းပြင်ဆင်ရန်",
        component: NewsEdit,
      },
      {
        path: "/goldPrice",
        name: "ရွှေဈေးများ",
        component: GoldPriceList,
      },
      {
        path: "/goldPrice-add",
        name: "ရွှေဈေးအသစ်တင်ရန်",
        component: GoldPriceAdd,
      },
      {
        path: "/goldPrice/:goldPriceId",
        name: "ရွှေဈေးပြင်ဆင်ရန်",
        component: GoldPriceEdit,
      },
      {
        path: "/giftCard",
        name: "လက်ဆောင်ကတ်များ",
        component: GiftCardList,
      },
      {
        path: "/giftCard-add",
        name: "လက်ဆောင်ကတ်အသစ်တင်ရန်",
        component: GiftCardAdd,
      },
      {
        path: "/giftCard/:giftCardId",
        name: "လက်ဆောင်ကတ်ပြင်ဆင်ရန်",
        component: GiftCardEdit,
      },
      {
        path: "/promotions",
        name: "ပရိုမိုးရှင်းများ",
        component: PromotionList,
      },
      {
        path: "/promotion-add",
        name: "ပရိုမိုးရှင်းအသစ်တင်ရန်",
        component: PromotionAdd,
      },
      {
        path: "/promotion/:promotionId",
        name: "ပရိုမိုးရှင်းပြင်ဆင်ရန်",
        component: PromotionEdit,
      },
      {
        path: "/settings",
        name: "ဆက်တင်များ",
        component: SettingList,
      },
      {
        path: "/setting-add",
        name: "ဆက်တင်အသစ်ထပ်ထည့်ရန်",
        component: SettingAdd,
      },
      {
        path: "/requests",
        name: "လဲလှယ်မှုများ",
        component: RequestList,
      },
      {
        path: "/requests-report",
        name: "လဲလှယ်မှုများ",
        component: RequestReport
      },
      {
        path: "/send-notifications",
        name: "Notification ပို့ရန်",
        component: SendNotification
      }
    ],
  },
  {
    path: "/auth",
    name: "AdminAuth",
    component: AdminAuth,
    redirect: "/auth/login",
    children: [
      {
        path: "/auth/login",
        name: "AdminLogin",
        component: AdminLogin,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const verifyAdminLogin = async function (next) {
  try {
    await Auth.verifyAdmin(store.state.user.token);
    next();
  } catch (error) {
    next("/auth/");
  }
};

export default router;
