<template>
    <div class="login d-flex justify-center align-center">
        <v-card width="400" class="transparent" flat>
            <v-card-text align="center">
                <v-img width="100" height="100" style="border-radius: 10px" :src="require('@/assets/LogoSm.jpg')"></v-img>
                <h2 class="my-10">Royal Tahlee Gold Club Admin</h2>
                <v-text-field
                    background-color="#fff"
                    outlined
                    rounded
                    label="အီးမေးလ်"
                    color="primary darken-3"
                    type="email"
                    v-model="email"
                />
                <v-text-field
                    background-color="#fff"
                    outlined
                    rounded
                    label="စကားဝှက်"
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    color="primary darken-3"
                    v-model="password"
                />
                <v-btn
                    class="primary"
                    dark
                    block
                    rounded
                    large
                    :loading="loading"
                    @click="handleLogin"
                >
                    အကောင့်သို့ဝင်ရန်
                </v-btn>
                 <v-alert v-if="error" type="error" class="my-10" dismissible>
                    {{error}}
                </v-alert>
                <v-alert v-if="success" type="success" class="my-10">
                    လုပ်ဆောင်နေပါသည် ...
                </v-alert>
            </v-card-text>
        </v-card>
    </div>  
</template>
<script>
import Auth from "@/api/model/Auth"
export default {
    name: "Login",
    data: function () {
        return {
            email: null,
            password: null,
            showPassword: null,
            loading: false,
            error: null,
            success: false
        }
    },
    methods: {
        handleLogin: async function () {
            this.loading = true
            let body = {
                email: this.email,
                password: this.password
            }
            try {
                let admin = await Auth.loginAdmin(body)
                this.$store.dispatch("SET_USER", admin)
                this.success = true
                setTimeout(()=> {
                    this.$router.push("/")
                }, 500)
            } catch (error) {
                this.loading = false
                if (error.message === "400") this.error = "အီးမေးလ် သို့မဟုတ် စကားဝှက်မှားယွင်းနေပါသည်"
                else this.error = "လုပ်ဆောင်၍ မရနိုင်သေးပါ"
            }
        }
    }
}
</script>