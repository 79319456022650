import { GET,POST } from "../fetch";
const root = "setting-manager"

export default {
    getTotal : async (token) => {
        try {
            let response = await GET(`/${root}/get-total`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    settingWithRange : async (skip,limit,token) =>{
        let body ={skip,limit}
        try {
            let response = await POST(`/${root}/setting-with-range`,body,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    searchSetting : async (searchText,token) => {
        try {
            let response = await GET(`/${root}/search/${searchText}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
}