<template>
	<div class="users">
		<v-container>
			<v-row>
				<v-col cols="12">
					<!-- List of students -->
					<v-data-table
						:page="page"
						:pageCount="numberOfPages"
						:search="search"
						:loading="loading"
						:headers="headers"
						:items-per-page="itemsPerPage"
						:server-items-length="totalCount"
						:options.sync="options"
						:items="users"
						class="elevation-1 dark--text text-caption px-5 mt-10"
					>
						<!-- Top of table -->
						<template v-slot:top>
							<v-row>
								<v-col cols="4">
									<!-- Qr Search Box -->
									<v-text-field
										v-model="userId"
										placeholder="QR ကုတ်ဖြင့်ရှာရန်"
										class="mx-4 dark--text text-body-2 px-5"
										clearable
										solo
										filled
										dense
										flat
										prepend-icon="qr_code_scanner"
										background-color="grey lighten-3"
										color="dark"
									></v-text-field>
									<!-- End Qr Search Box -->
								</v-col>
								<v-col cols="4">
									<!-- Search Box -->
									<form v-on:submit.prevent="searchItem">
										<v-text-field
											v-model="search"
											placeholder="စာသားရိုက်ရှာရန်"
											class="mx-4 dark--text text-body-2 px-5"
											clearable
											solo
											filled
											dense
											flat
											prepend-icon="refresh"
											append-icon="search"
											background-color="grey lighten-3"
											color="dark"
											@click:append="searchItem"
											@click:prepend="
												() => readDataWithLimit(1, itemsPerPage)
											"
											@click:clear="readDataWithLimit(1, itemsPerPage)"
										>
										</v-text-field>
									</form>
									<!-- End Search Box -->
								</v-col>
								<v-col cols="4">
									<v-btn
										depressed
										rounded
										block
										class="primary mx-2"
										dark
										@click="() => $router.push('/users-report')"
									>
										<v-icon>summarize</v-icon>
										Report ထုတ်မည်
									</v-btn>
								</v-col>
							</v-row>
						</template>
						<!-- End of top -->
						<template v-slot:no-data>
							<v-row>
								<v-col cols="12" align="center" class="dark--text my-15">
									<v-icon>info</v-icon> {{ "no_data" }}
								</v-col>
							</v-row>
						</template>

						<!-- Slot for name -->
						<!-- Slot for golds -->
						<template v-slot:[`item.username`]="{ item }">
							<p class="body-2 dark--text font-weight-medium name">
								{{ item.username }}
							</p>
						</template>
						<!-- Slot for image -->
						<template v-slot:[`item.profile`]="{ item }">
							<v-img
								:src="item.profile ? item.profile : require('@/assets/LogoSm.jpg')"
								width="60"
								height="60"
								class="my-2"
							></v-img>
						</template>
						<!-- / Slot for image -->
						<!-- slot for userId -->
						<template v-slot:[`item.tlId`]="{ item }">
							<p
								@click="$router.push(`/users/${item._id}`)"
								class="body-2 primary--text font-weight-bold text-decoration-underline name"
							>
								{{ item.tlId ? item.tlId : "-" }}
							</p>
						</template>
						<!-- Slot for phone number -->
						<!-- Slot for golds -->
						<template v-slot:[`item.phoneNumber`]="{ item }">
							<p class="body-2 dark--text font-weight-medium name">
								{{ item.phoneNumber }}
							</p>
						</template>
						<!-- / Slot for phone number -->
						<!-- Slot for golds -->
						<template v-slot:[`item.points`]="{ item }">
							<p class="body-2 dark--text font-weight-medium name">
								{{ item.points }}
							</p>
						</template>
						<!-- Slot for golds -->
						<!-- Slot for birthday -->
						<!-- Slot for golds -->
						<template v-slot:[`item.birthday`]="{ item }">
							<p class="body-2 dark--text font-weight-medium name">
								{{
									new Date(item.birthday).toLocaleDateString("en-CL") ==
									"Invalid Date"
										? item.birthday
										: new Date(item.birthday).toLocaleDateString("en-IN").replaceAll("/", "-")
								}}
							</p>
						</template>
						<template v-slot:[`item.delete`]="{ item }">
							<v-btn
								small
								icon
								@click="
									() => {
										toDelete = item._id
										dialog = true
									}
								"
								><v-icon>delete</v-icon></v-btn
							>
						</template>
						<!-- / delete Slot -->
					</v-data-table>
					<confirm-box
						:dialog="dialog"
						@closeDialog="() => (dialog = false)"
						@deleteItem="deleteItem"
					/>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import User from "../../api/model/User"
import System from "../../api/model/System"
import ConfirmBox from "../../components/Util/ConfrimBox.vue"
import { ExportToCsv } from "export-to-csv"

export default {
	name: "Users",
	components: { ConfirmBox },
	data: function () {
		return {
			page: 1,
			totalCount: null,
			numberOfPages: null,
			itemsPerPage: 10,
			loading: false,
			options: {},
			search: null,
			headers: [
				{ text: "အိုင်ဒီ", value: "tlId" },
				{ text: "ဓာတ်ပုံ", value: "profile" },
				{ text: "အမည်", value: "username" },
				{ text: "မွေးနေ့", value: "birthday" },
				{ text: "Golds", value: "points" },
				{ text: "ဖုန်းနံပါတ်", value: "phoneNumber" },
				{ text: "ဖျက်ရန်", value: "delete" },
			],
			users: [],
			birthdayUesrs: null,
			dialog: false,
			toDelete: null,
			userId: null,
		}
	},
	methods: {
		getExportData() {
			let exportData = []
			this.users.map((data) => {
				exportData.push({
					Username: data.username,
					UserID: data.tlId,
					PhoneNumber: data.phoneNumber,
					Gender: data.isMale ? "Male" : "Female",
					Birthday: data.birthday,
					NrcNumber: data.nrcNumber,
					Address: data.address,
					Golds: data.points,
				})
			})
			return exportData
		},
		exportUserData: function () {
			const options = {
				fieldSeparator: ",",
				quoteStrings: '"',
				decimalSeparator: ".",
				showLabels: true,
				showTitle: true,
				title: "Royal Tahlee Gold Club Members",
				useTextFile: false,
				useBom: true,
				useKeysAsHeaders: true,
				filename: "Royal Tahlee Gold Club Members",
			}
			const csvExporter = new ExportToCsv(options)
			csvExporter.generateCsv(this.getExportData())
		},
		readDataWithLimit: async function (page, itemsPerPage) {
			// Table is loading
			this.loading = true
			try {
				// First get the server item length
				var result = await System.totalUser(this.token)
				this.totalCount = result

				// Calculate skip and limit
				var skip
				var limit
				if (itemsPerPage == -1) {
					skip = 0
					limit = this.totalCount
				} else {
					skip = this.totalCount - itemsPerPage * page

					if (skip < 0) {
						skip = 0
						limit = itemsPerPage + skip
					} else {
						limit = itemsPerPage
					}
				}

				// Read data with limit
				result = await System.userWithRange(skip, limit, this.token)
				this.users = result
				this.loading = false
			} catch (err) {
				this.loading = false
				console.log("Error : ", err)
				this.$store.dispatch("MAKE_ERROR", "Cannot fetch student data !")
			}
		},
		searchItem: async function () {
			this.loading = true
			if (!this.search) {
				// if search text is null reload with default
				this.readDataWithLimit(1, this.itemsPerPage)
			} else {
				try {
					this.users = await System.searchUser(this.search, this.token)
					this.totalCount = this.users.length
					this.loading = false
				} catch (err) {
					this.loading = false
					console.log("Error : ", err)
					this.$store.dispatch("MAKE_ERROR", "Unable to search !")
				}
			}
		},
		searchWithUserId: async function () {
			console.log("Searching...")
			this.loading = true
			if (this.userId) {
				try {
					let result = await User.getInfo(this.userId, this.token)
					this.users = [result]
				} catch (error) {
					console.log("Unable to search user : ", error)
				}
			}
			this.loading = false
		},
		deleteItem: async function () {
			this.dialog = false
			try {
				await User.delete(this.toDelete, this.token)
			} catch (error) {
				this.dialog = false
				console.log("Error:", error)
				this.$store.dispatch("MAKE_ERROR", "Unable to delete")
			}
			this.readDataWithLimit(this.options.page, this.options.itemsPerPage)
		},
	},
	watch: {
		// Do this function everytime the options change
		options: function (options) {
			this.readDataWithLimit(options.page, options.itemsPerPage)
		},
		userId: function (userId) {
			if (userId) this.searchWithUserId()
			else this.readDataWithLimit(this.options.page, this.options.itemsPerPage)
		},
	},
	created: async function () {
		try {
			let data = await System.getBirthdayUsers(this.token)
			this.birthdayUesrs = data
		} catch (error) {
			this.$store.dispatch("MAKE_ERROR", "Unable to load")
		}
	},
	computed: {
		token: function () {
			return this.$store.state.user.token
		},
	},
}
</script>
<style scoped>
.name:hover {
	cursor: pointer;
}
</style>
