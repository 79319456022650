import { GET, DELETE, PATCH } from "../fetch";
const root = "redeem";

export default {
  getInfo: async function (id, token) {
    try {
      let response = await GET(`/${root}/get-info/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  delete: async function (id, token) {
    try {
      let response = await DELETE(`/${root}/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  accept: async function (id, token, body) {
    try {
      let response = await PATCH(`/${root}/accept/${id}`, token, body);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  reject: async function (id, token, admin) {
    console.log(admin);
    let body = { admin };
    try {
      let response = await PATCH(`/${root}/reject/${id}`, token, body);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
