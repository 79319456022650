var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discount"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 dark--text text-caption px-5 mt-10",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalCount,"options":_vm.options,"items":_vm.discount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchItem.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mx-4 dark--text text-body-2 px-5",attrs:{"placeholder":"Search","clearable":"","solo":"","filled":"","dense":"","flat":"","prepend-icon":"refresh","append-icon":"search","background-color":"grey lighten-3","color":"dark"},on:{"click:append":_vm.searchItem,"click:prepend":function () { return _vm.readDataWithLimit(1, _vm.itemsPerPage); },"click:clear":function($event){return _vm.readDataWithLimit(1, _vm.itemsPerPage)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{attrs:{"cols":"6","align":"right"}},[_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","depressed":"","link":"","to":'/discount-add',"large":""}},[_c('v-icon',[_vm._v("add")]),_vm._v(" အသစ်ဖွင့်မည် ")],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"dark--text my-15",attrs:{"cols":"12","align":"center"}},[_c('v-icon',[_vm._v("info")]),_vm._v(" "+_vm._s("no_data")+" ")],1)],1)]},proxy:true},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-2 blue--text mt-3 text-decoration-underline name",on:{"click":function($event){return _vm.$router.push(("/discount/" + (item._id)))}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"my-2",attrs:{"src":item.photo ? item.photo : require('@/assets/LogoSm.jpg'),"width":"60","height":"60"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editItem(item._id)}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function () {
                  _vm.toDelete = item._id;
                  _vm.dialog = true;
                }}},[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)}),_c('confirm-box',{attrs:{"dialog":_vm.dialog},on:{"closeDialog":function () { return (_vm.dialog = false); },"deleteItem":_vm.deleteItem}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }