<template>
    <div class="admins">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <!-- List of students -->
                    <v-data-table
                        :page="page"
                        :pageCount="numberOfPages"
                        :search="search"
                        :loading="loading"
                        :headers="headers"
                        :items-per-page="itemsPerPage"
                        :server-items-length="totalCount"
                        :options.sync="options"
                        :items="admins"
                        class="elevation-1 dark--text text-caption px-5 mt-10"
                    >
                        <!-- Top of table -->
                        <template v-slot:top>
                            <v-row>
                                <v-col cols="6">
                                    <!-- Search Box -->
                                    <form v-on:submit.prevent="searchItem">
                                        <v-text-field
                                            v-model="search"
                                            placeholder="Search"
                                            class="mx-4 dark--text text-body-2 px-5"
                                            clearable
                                            solo
                                            filled
                                            dense
                                            flat
                                            prepend-icon="refresh"
                                            append-icon="search"
                                            background-color="grey lighten-3"
                                            color="dark"
                                            @click:append="searchItem"
                                            @click:prepend="
                                                () => readDataWithLimit(1, itemsPerPage)
                                            "
                                            @click:clear="readDataWithLimit(1, itemsPerPage)"
                                        >
                                        </v-text-field>
                                    </form>
                                </v-col>
                                <v-col cols="6" align="right">
                                    <v-btn
                                        rounded
                                        depressed
                                        class="primary"
                                        link
                                        :to="'/admin-add'"
                                        large
                                    >
                                        <v-icon>add</v-icon>
                                        အသစ်ဖွင့်မည်
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <!-- End of top -->
                        <template v-slot:no-data>
                            <v-row>
                                <v-col cols="12" align="center" class="dark--text my-15">
                                    <v-icon>info</v-icon> {{ "no_data" }}
                                </v-col>
                            </v-row>
                        </template>

                        <!-- Slot for name -->
                        <template v-slot:[`item.name`]="{ item }">
                            <p
                                @click="$router.push(`/admins/${item._id}`)"
                                class="body-2 blue--text mt-3 text-decoration-underline name"
                            >
                                {{ item.name }}
                            </p>
                        </template>
                        <!-- Slot for email -->
                        <template v-slot:[`item.email`]="{ item }">
                            {{ item.email }}
                        </template>
                        <!-- < /Slot fot email> -->
                        <!-- Slot for role -->
                        <template v-slot:[`item.role`]="{ item }">
                            <v-chip :class="getChipColor(item.role)">{{
                                getRoleLabel(item.role)
                            }}</v-chip>
                        </template>
                        <!-- /Slot for role -->
                        <!-- slot for edit -->
                        <template v-slot:[`item.edit`]="{ item }">
                            <v-btn small icon @click="editItem(item._id)"
                                ><v-icon>edit</v-icon></v-btn
                            >
                        </template>
                        <!-- slot for edit item -->

                        <!-- Slot for delete -->
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-btn
                                small
                                icon
                                @click="
                                    () => {
                                        toDelete = item._id;
                                        dialog = true;
                                    }
                                "
                                ><v-icon>delete</v-icon></v-btn
                            >
                        </template>
                        <!-- / delete Slot -->
                    </v-data-table>
                    <confirm-box
                        :dialog="dialog"
                        @closeDialog="() => (dialog = false)"
                        @deleteItem="deleteItem"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Admin from "../../api/model/Admin";
import System from "../../api/model/System";
import ConfirmBox from "../../components/Util/ConfrimBox.vue";
export default {
    name: "Admins",
    components: { ConfirmBox },
    data: function () {
        return {
            page: 1,
            totalCount: null,
            numberOfPages: null,
            itemsPerPage: 10,
            loading: false,
            options: {},
            search: null,
            headers: [
                { text: "အမည်", value: "name" },
                { text: "အီးမေးလ်", value: "email" },
                { text: "စကားဝှက်", value: "password" },
                { text: "ဖုန်း", value: "phone" },
                { text: "အဆင့်", value: "role" },
                { text: "ဖျက်မည်", value: "delete" },
            ],
            admins: [],
            dialog: false,
            toDelete: null,
        };
    },
    methods: {
        getChipColor: function (role) {
            const colors = ["yellow", "green", "blue", "primary"];
            return colors[role];
        },
        getRoleLabel: function (role) {
            const labels = ["စူပါ အက်ဒမင်", "အက်ဒမင်", "Sales အက်ဒမင်", "အက်ဒီတာ"];
            return labels[role];
        },
        readDataWithLimit: async function (page, itemsPerPage) {
            // Table is loading
            this.loading = true;
            try {
                // First get the server item length
                var result = await System.totalAdmin(this.token);
                this.totalCount = result;
                // Calculate skip and limit
                var skip = this.totalCount - itemsPerPage * page;
                var limit;
                if (skip < 0) {
                    skip = 0;
                    limit = itemsPerPage + skip;
                } else {
                    limit = itemsPerPage;
                }

                // Read data with limit
                result = await System.adminWithRange(skip, limit, this.token);
                this.admins = result;
                this.loading = false;
            } catch (err) {
                this.loading = false;
                console.log("Error : ", err);
                this.$store.dispatch("MAKE_ERROR", "Cannot fetch admin data !");
            }
        },
        searchItem: async function () {
            this.loading = true;
            if (!this.search) {
                // if search text is null reload with default
                this.readDataWithLimit(1, this.itemsPerPage);
            } else {
                try {
                    this.admins = await System.searchAdmin(this.search, this.token);
                    this.totalCount = this.admins.length;
                    this.loading = false;
                } catch (err) {
                    this.loading = false;
                    console.log("Error : ", err);
                    this.$store.dispatch("MAKE_ERROR", "Unable to search !");
                }
            }
        },
        deleteItem: async function () {
            this.dialog = false;
            try {
                await Admin.delete(this.toDelete, this.token);
            } catch (error) {
                this.dialog = false;
                console.log("Error:", error);
                this.$store.dispatch("MAKE_ERROR", "Unable to delete");
            }
            this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
        },
        editItem: function (adminId) {
            this.$router.push("/admin/admins/" + adminId);
        },
    },
    watch: {
        // Do this function everytime the options change
        options: function (options) {
            this.readDataWithLimit(options.page, options.itemsPerPage);
        },
    },

    computed: {
        token: function () {
            return this.$store.state.user.token;
        },
    },
};
</script>
<style scoped>
.name:hover {
    cursor: pointer;
}
</style>
