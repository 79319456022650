<template>
  <div class="GoldPrice-add">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="mt-8">
            <v-card-title class="dark--text subtitle-1 font-weight-bold">
              <v-btn
                depressed
                rounded
                class="secondary mx-2"
                dark
                @click="() => $router.go(-1)"
              >
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              အသစ်ဖွင့်ရန်
              <v-spacer></v-spacer>
              <v-btn
                rounded
                depressed
                class="primary"
                @click="createItem()"
                large
                dark
                :loading="loading"
              >
                <v-icon>save</v-icon>
                သိမ်းမည်
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-3">
              <v-row>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    ၁၅ ပဲရည်(ဝယ်ဈေး) -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="eighteen.buy"
                    type="text"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    ၁၅ ပဲရည်(ရောင်းဈေး) -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="eighteen.sell"
                    type="text"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    ၁၆ ပဲရည်(ဝယ်ဈေး) -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="twenty_four.buy"
                    type="text"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    ၁၆ ပဲရည်(ရောင်းဈေး) -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="twenty_four.sell"
                    type="text"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import GoldPriceManager from "../../api/model/GoldPriceManager";
export default {
  name: "GoldPriceAdd",
  data: function () {
    return {
      loading: false,
      twenty_four: {
        buy: null,
        sell: null,
      },
      eighteen: {
        buy: null,
        sell: null,
      },
    };
  },
  methods: {
    createItem: async function () {
      this.loading = true;
      var goldPrice = {};
      goldPrice["18k"] = this.eighteen;
      goldPrice["24k"] = this.twenty_four;
      try {
        await GoldPriceManager.createGoldPrice(goldPrice, this.token);
        this.$router.push("/goldPrice");
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot Add GoldPrice !");
      }
    },
  },
  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
};
</script>
