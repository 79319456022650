<template>
    <div class="admins-add">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card class="mt-8">
                        <v-card-title class="dark--text subtitle-1 font-weight-bold">
                            <v-btn
                                depressed
                                rounded
                                class="secondary mx-2"
                                dark
                                @click="() => $router.go(-1)"
                            >
                                <v-icon>keyboard_arrow_left</v-icon>
                            </v-btn>
                            အသစ်ဖွင့်ရန်
                            <v-spacer></v-spacer>
                            <v-btn
                                rounded
                                depressed
                                class="primary"
                                @click="createNewAdmin()"
                                large
                                dark
                                :loading="loading"
                            >
                                <v-icon>save</v-icon>
                                သိမ်းမည်
                            </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="mt-3">
                            <v-row>
                                <v-col cols="12" md="4">
                                    <p class="dark--text body-2 font-weight-bold">အမည် -</p>
                                    <v-text-field
                                        solo
                                        background-color="grey lighten-2"
                                        flat
                                        rounded
                                        class="dark--text"
                                        v-model="name"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <p class="dark--text body-2 font-weight-bold">အီးမေးလ် -</p>
                                    <v-text-field
                                        solo
                                        background-color="grey lighten-2"
                                        flat
                                        rounded
                                        class="dark--text"
                                        v-model="email"
                                        type="email"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <p class="dark--text body-2 font-weight-bold">ဖုန်း -</p>
                                    <v-text-field
                                        solo
                                        background-color="grey lighten-2"
                                        flat
                                        rounded
                                        class="dark--text"
                                        v-model="phone"
                                        type="phone"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <p class="dark--text body-2 font-weight-bold">အဆင့် -</p>
                                    <v-select
                                        solo
                                        background-color="grey lighten-2"
                                        flat
                                        rounded
                                        class="dark--text"
                                        :items="roles"
                                        item-text="name"
                                        item-value="value"
                                        v-model="role"
                                    />
                                </v-col>
                                <v-col cols="12" md="4">
                                    <p class="dark--text body-2 font-weight-bold">စကားဝှက် -</p>
                                    <v-text-field
                                        solo
                                        background-color="grey lighten-2"
                                        flat
                                        rounded
                                        class="dark--text"
                                        v-model="password"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <p class="dark--text body-2 font-weight-bold">
                                        စကားဝှက် အတည်ပြုမည် -
                                    </p>
                                    <v-text-field
                                        solo
                                        background-color="grey lighten-2"
                                        flat
                                        rounded
                                        class="dark--text"
                                        :color="password == confirmPassword ? 'green' : 'red'"
                                        v-model="confirmPassword"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import Auth from "../../api/model/Auth";
export default {
    name: "AdminAdd",
    data: function () {
        return {
            loading: false,
            name: null,
            email: null,
            phone: null,
            password: null,
            confirmPassword: null,
            role: 0,
            roles: [
                {
                    name: "Super အက်ဒမင်",
                    value: 0,
                },
                {
                    name: "အက်ဒမင်",
                    value: 1,
                },
                {
                    name: "Sales အက်ဒမင်",
                    value: 2,
                },
                {
                    name: "အက်ဒီတာ",
                    value: 3,
                },
            ],
        };
    },
    methods: {
        createNewAdmin: async function () {
            this.loading = true;
            var admin = {
                name: this.name,
                email: this.email,
                phone: this.phone,
                role: this.role,
                password: this.password,
            };
            try {
                await Auth.registerAdmin(admin, this.token);
                this.$router.push("/admins");
            } catch (err) {
                this.loading = false;
                console.log("Error : ", err);
                this.$store.dispatch("MAKE_ERROR", "Cannot Add Admin !");
            }
        },
    },
    computed: {
        token: function () {
            return this.$store.state.user.token;
        },
    },
};
</script>
