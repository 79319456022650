import { POST, GET } from "../fetch";
const root = "auth";

export default {
  registerAdmin: async function (body, token) {
    try {
      let response = await POST(`/${root}/register-admin`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  loginAdmin: async function (body) {
    try {
      let response = await POST(`/${root}/login-admin`, body);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  verifyAdmin: async function (token) {
    try {
      let response = await GET(`/${root}/verify-admin`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  
  
};
