<template>
    <div class="dashboard">
        <v-container>
            <v-row>
                <v-col cols="12" md="4">
                    <v-card flat>
                        <v-list-item>
                            <v-list-item-title class="font-weight-bold">
                                စုစုပေါင်း Gold Club အသုံးပြုသူများ
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text class="py-6">
                            <v-row>
                                <v-col cols="12" align="center">
                                    <v-img
                                        src="../../assets/designs/man.png"
                                        width="70"
                                        style="margin-bottom: 10px"
                                    />
                                    <h2 class="display primary--text">
                                        {{ totalUsers }}
                                    </h2>
                                    <h4>ယောက်</h4>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn link flat text color="primary" to="/users">
                                ကြည့်မည်
                                <v-icon>launch</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" tile>
                    <v-card flat>
                        <v-list-item>
                            <v-list-item-title class="font-weight-bold">
                                လက်ဆောင်ပစ္စည်းများ
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text class="py-6">
                            <v-row>
                                <v-col cols="6" align="center">
                                    <v-img
                                        src="../../assets/designs/gift-box.png"
                                        width="70"
                                        style="margin-bottom: 10px"
                                    />
                                    <h2 class="display primary--text">{{ totalItems }}</h2>
                                    <h4>ပစ္စည်း</h4>
                                </v-col>
                                <v-col cols="6" align="center">
                                    <v-img
                                        src="../../assets/designs/discount.png"
                                        width="70"
                                        style="margin-bottom: 10px"
                                    />
                                    <h2 class="display primary--text">{{ totalDiscounts }}</h2>
                                    <h4>လျော့စျေး</h4>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn link flat text color="primary" to="/items">
                                ပစ္စည်းများ
                                <v-icon>launch</v-icon>
                            </v-btn>
                            <v-btn link flat text color="primary" to="/discounts">
                                လျော့ဈေးများ
                                <v-icon>launch</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4" tile>
                    <v-card tile flat>
                        <v-list-item>
                            <v-list-item-title class="font-weight-bold">
                                ရွှေစျေးများ
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text class="py-6">
                            <v-row>
                                <v-col cols="6" align="center">
                                    <v-img
                                        src="../../assets/designs/eighteen.png"
                                        width="70"
                                        style="margin-bottom: 10px"
                                    />
                                    <h4 class="display primary--text">
                                        {{
                                            JSON.stringify(latestGoldPrice["18k"].buy)
                                                .replace('"', "")
                                                .replace('"', "")
                                        }}/
                                        {{
                                            JSON.stringify(latestGoldPrice["18k"].sell)
                                                .replace('"', "")
                                                .replace('"', "")
                                        }}
                                    </h4>
                                    <h4>၁၅ပဲရည် (ဝယ်/ရောင်း)</h4>
                                </v-col>
                                <v-col cols="6" align="center">
                                    <v-img
                                        src="../../assets/designs/twenty-four.png"
                                        width="70"
                                        style="margin-bottom: 10px"
                                    />
                                    <h4 class="display primary--text">
                                        {{
                                            JSON.stringify(latestGoldPrice["24k"].buy)
                                                .replace('"', "")
                                                .replace('"', "")
                                        }}/
                                        {{
                                            JSON.stringify(latestGoldPrice["24k"].sell)
                                                .replace('"', "")
                                                .replace('"', "")
                                        }}
                                    </h4>
                                    <h4>၁၆ပဲရည် (ဝယ်/ရောင်း)</h4>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn link flat text color="primary" to="/items">
                                ပစ္စည်းများ
                                <v-icon>launch</v-icon>
                            </v-btn>
                            <v-btn link flat text color="primary" to="/discounts">
                                လျော့ဈေးများ
                                <v-icon>launch</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card height="400" flat>
                        <v-list-item>
                            <v-list-item-title class="font-weight-bold">
                                <v-icon>cake</v-icon>
                                ယနေ့ မွေးနေ့ရှင်များ
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-simple-table height="300">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">အမည်</th>
                                            <th class="text-left">Golds</th>
                                            <th class="text-left">ဖုန်းနံပါတ်</th>
                                            <th class="text-left">အသေးစိတ်ကြည့်မည်</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="birthdayUsers">
                                        <tr v-for="user in birthdayUsers" :key="user._id">
                                            <td>{{ user.username }}</td>
                                            <td>{{ user.points }}</td>
                                            <td>{{ user.phoneNumber }}</td>
                                            <td>
                                                <v-btn
                                                    small
                                                    text
                                                    color="primary"
                                                    link
                                                    :to="`/users/${user._id}`"
                                                >
                                                    ကြည့်မည်
                                                    <v-icon>launch</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card height="400" flat>
                        <v-list-item>
                            <v-list-item-title class="font-weight-bold">
                                <v-icon>notifications</v-icon>
                                Giftcard သက်တမ်းကုန်ခါနီး အသုံးပြုသူများ
                            </v-list-item-title>
                            <v-list-item-action>
                                <v-btn
                                    icon
                                    @click="handleSendExpireNotifications"
                                    :loading="notificationLoading"
                                >
                                    <v-icon color="primary">notifications</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-simple-table height="300">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">လက်ခံသူ</th>
                                            <th class="text-left">Golds</th>
                                            <th class="text-left">ကုန်ဆုံးမည့်နေ့</th>
                                            <th class="text-left">အသေးစိတ်ကြည့်မည်</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="card in giftcardsGoingToExpire" :key="card._id">
                                            <td>{{ card.receiver.username }}</td>
                                            <td>{{ card.amount }}</td>
                                            <td>
                                                {{ new Date(card.expireDate).toLocaleDateString() }}
                                            </td>
                                            <td>
                                                <v-btn
                                                    small
                                                    text
                                                    color="primary"
                                                    link
                                                    :to="`/users/${card.to}`"
                                                >
                                                    ကြည့်မည်
                                                    <v-icon>launch</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import System from "../../api/model/System";
import ItemManager from "../../api/model/ItemManager";
import DiscountManager from "../../api/model/DiscountManager";
import GoldPriceManager from "../../api/model/GoldPriceManager";
import GiftcardRecordsManager from "../../api/model/GiftcardRecordsManager";
import ExpoNotification from "../../api/model/ExpoNotification";

export default {
    name: "Dashboard",
    data: function () {
        return {
            totalUsers: "00",
            totalItems: "00",
            totalDiscounts: "00",
            latestGoldPrice: null,
            birthdayUsers: null,
            giftcardsGoingToExpire: [],
            notificationLoading: false,
        };
    },
    methods: {
        getGiftcardsGoingToExpire: async function () {
            try {
                let result = await GiftcardRecordsManager.getGiftcardsGoingToExpire(this.token);
                this.giftcardsGoingToExpire = result;
            } catch (error) {
                console.log(error);
            }
        },
        getTotalUsers: async function () {
            try {
                let result = await System.totalUser(this.token);
                this.totalUsers = result;
            } catch (error) {
                console.log("Unable to get total uesrs : ", error);
            }
        },
        getTotalRedeems: async function () {
            try {
                let totalItems = await ItemManager.getTotal(this.token);
                this.totalItems = totalItems;

                let totalDiscounts = await DiscountManager.totalDiscount(this.token);
                this.totalDiscounts = totalDiscounts;
            } catch (error) {
                console.log("Unable to get redeem items : ", error);
            }
        },
        getLatestGoldPrice: async function () {
            try {
                let latestGoldPrice = await GoldPriceManager.latestGoldPrice(this.token);
                if (latestGoldPrice.length > 0) {
                    console.log(latestGoldPrice[0]);
                    this.latestGoldPrice = latestGoldPrice[0];
                }
            } catch (error) {
                console.log("Unable to load gold price : ", error);
            }
        },
        getBirthdayUsers: async function () {
            try {
                let birthdayUsers = await System.getBirthdayUsers(this.token);
                this.birthdayUsers = birthdayUsers;
            } catch (error) {
                console.log("Unable to load birthday users : ", error);
            }
        },
        handleSendExpireNotifications: async function () {
            this.notificationLoading = true;

            let tokensToPush = [];
            for (let i = 0; i < this.giftcardsGoingToExpire.length; i++) {
                let tokens = this.giftcardsGoingToExpire[i].receiver.expoTokens;
                if (tokens) {
                    for (let j = 0; j < tokens.length; j++) {
                        if (!tokensToPush.includes(tokens[j])) {
                            tokensToPush.push(tokens[j]);
                        }
                    }
                }
            }

            let notification = {
                tokens: tokensToPush,
                title: "Birthday gift card is going to expire",
                body: "Your gift card expire date is less than 10 days. Make sure to use it at our shop before it expires.",
            };

            try {
                await ExpoNotification.sendNotification(notification, this.token);
            } catch (error) {
                console.log();
            }

            this.notificationLoading = false;
        },
    },
    created: function () {
        this.getTotalUsers();
        this.getTotalRedeems();
        this.getLatestGoldPrice();
        this.getBirthdayUsers();
        this.getGiftcardsGoingToExpire();
    },
    computed: {
        token: function () {
            return this.$store.state.user.token;
        },
    },
};
</script>
