<template>
  <div class="app-bar">
    <v-app-bar app class="primary" flat>
      <Navbar />
      <v-toolbar-title class="white--text font-weight-bold">{{
        $route.name
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text dark @click="dialog = true">
        <v-icon>logout</v-icon>
        အကောင့်မှထွက်မည်
      </v-btn>
    </v-app-bar>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title font-weight-bold primary--text">
          အကောင့်မှထွက်ခွာမည်
        </v-card-title>
        <v-card-text
          >အကောင့်မှထွက်ခွာရန် သေချာပါသလား ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1 font-weight-bold" text @click="dialog = false">
            မလုပ်ပါ
          </v-btn>
          <v-btn color="red darken-1 font-weight-bold" text @click="handleLogout">
            ထွက်ခွာမည်
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Navbar from "@/components/Util/Navbar"
export default {
  name: "Appbar",
  components: { Navbar },
  data: function () {
      return {
          dialog: false
      }
  },
  methods: {
    handleLogout: function () {
      this.$store.dispatch("SET_USER", null);
      this.$router.push("/auth");
    },
  },
};
</script>