<template>
  <div class="goldPrice">
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- List of students -->
          <v-data-table
            :page="page"
            :pageCount="numberOfPages"
            :search="search"
            :loading="loading"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :server-items-length="totalCount"
            :options.sync="options"
            :items="goldPrice"
            class="elevation-1 dark--text text-caption px-5 mt-10"
          >
            <!-- Top of table -->
            <template v-slot:top>
              <v-row>
                <v-col cols="6">

                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    rounded
                    depressed
                    class="primary"
                    link
                    :to="'/goldPrice-add'"
                    large
                  >
                    <v-icon>add</v-icon>
                    အသစ်ဖွင့်မည်
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <!-- End of top -->
            <template v-slot:no-data>
              <v-row>
                <v-col cols="12" align="center" class="dark--text my-15">
                  <v-icon>info</v-icon> {{ "no_data" }}
                </v-col>
              </v-row>
            </template>

            <!-- slot for date -->
             <template v-slot:[`item.createdAt`]="{ item }">
              {{ new Date(item.createdAt).toLocaleDateString() }}
            </template>
            <!-- slot for edit -->
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn small icon @click="editItem(item._id)"
                ><v-icon>edit</v-icon></v-btn
              >
            </template>
            <!-- slot for edit item -->

            <!-- Slot for delete -->
           <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                small
                icon
                @click="
                  () => {
                    toDelete = item._id;
                    dialog = true;
                  }
                "
                ><v-icon>delete</v-icon></v-btn
              >
            </template>
            <!-- / delete Slot -->
            
          </v-data-table>
            <confirm-box
            :dialog="dialog"
            @closeDialog="() => (dialog = false)"
            @deleteItem="deleteItem"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import GoldPrice from "../../api/model/GoldPrice"
import GoldPriceManager  from "../../api/model/GoldPriceManager"
import ConfirmBox from "../../components/Util/ConfrimBox.vue"
export default {
  name: "GoldPriceList",
  components: {ConfirmBox},
  data: function () {
    return {
      page: 1,
      totalCount: null,
      numberOfPages: null,
      itemsPerPage: 10,
      loading: false,
      options: {},
      search: null,
      headers: [
        { text: "၁၅ ပဲရည်(ဝယ်ဈေး)", value : "18k.buy"},
        { text: "၁၅ ပဲရည်(ရောင်းဈေး)", value : "18k.sell"},
        { text: "၁၆ ပဲရည်(ဝယ်ဈေး)",value:"24k.buy"},
        { text: "၁၆ ပဲရည်(ရောင်းဈေး)",value :"24k.sell"},
        { text: "နေ့စွဲ",value :"createdAt"},
        { text: "ပြင်ဆင်မည်", value : "edit" },
        { text: "ဖျက်မည်", value: "delete" },
      ],
      goldPrice: [],
      dialog:false,
      toDelete:null,
    };
  },
  methods: {
    readDataWithLimit: async function (page, itemsPerPage) {
      // Table is loading
      this.loading = true;
      try {
        // First get the server item length
        var result = await GoldPriceManager.totalGoldPrice(this.token);
        this.totalCount = result;
        // Calculate skip and limit
        var skip = this.totalCount - itemsPerPage * page;
        var limit;
        if (skip < 0) {
          skip = 0;
          limit = itemsPerPage + skip;
        } else {
          limit = itemsPerPage;
        }

        // Read data with limit
        result = await GoldPriceManager.goldPriceWithRange(skip,limit,this.token);
        this.goldPrice = result;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot fetch goldPrice data !");
      }
    },
    searchItem: async function () {
      this.loading = true;
      if (!this.search) {
        // if search text is null reload with default
        this.readDataWithLimit(1, this.itemsPerPage);
      } else {
        try {
          this.goldPrice = await GoldPriceManager.searchGoldPrice(this.search,this.token);
          this.totalCount = this.goldPrice.length;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log("Error : ", err);
          this.$store.dispatch("MAKE_ERROR", "Unable to search !");
        }
      }
    },
    deleteItem :async function (){
    this.dialog= false;
    try {
      await GoldPrice.delete(this.toDelete,this.token)
      
    } catch (error) {
      this.dialog = false;
      console.log("Error:",error)
      this.$store.dispatch("MAKE_ERROR","Unable to delete")
    }
    this.readDataWithLimit(this.options.page,this.options.itemsPerPage)
  },
    editItem :function(goldPriceId){
        this.$router.push("/goldPrice/"+goldPriceId)
    },
    
  },
  watch: {
    // Do this function everytime the options change
    options: function (options) {
      this.readDataWithLimit(options.page, options.itemsPerPage);
    },
  },

  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
};
</script>
<style scoped>
.name:hover {
  cursor: pointer;
}
</style>