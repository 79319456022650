<template>
	<div class="user-deatils">
		<v-container v-if="user">
			<v-row>
				<v-col cols="12">
					<v-card class="mt-8">
						<v-card-title class="dark--text subtitle-1 font-weight-bold">
							<v-row class="px-3 py-3">
								<v-row>
									<v-btn
										depressed
										rounded
										class="secondary mx-2"
										dark
										@click="() => $router.go(-1)"
									>
										<v-icon>keyboard_arrow_left</v-icon>
									</v-btn>
									အသုံးပြုသူဒေတာ
									<v-spacer></v-spacer>
									<v-btn
										depressed
										class="primary mx-1 my-1"
										@click="pointAddDialog = true"
										small
									>
										<v-icon>add</v-icon>
										Golds ထည့်ပေးရန်
									</v-btn>
									<v-btn
										depressed
										class="primary mx-1 my-1"
										@click="priceAddDialog = true"
										small
									>
										<v-icon>payments</v-icon>
										Price နှင့်ထည့်ပေးရန်
									</v-btn>
									<v-btn
										depressed
										class="primary mx-1 my-1"
										@click="birthdayDialog = true"
										small
									>
										<v-icon>cake</v-icon>
										မွေးနေ့လက်ဆောင်ပေးရန်
									</v-btn>
									<v-btn
										depressed
										class="primary mx-1 my-1"
										@click="chineseNewYearDialog = true"
										small
									>
										<v-icon>redeem</v-icon>
										နှစ်သစ်ကူးလက်ဆောင်ပေးရန်
									</v-btn>
								</v-row>
							</v-row>
						</v-card-title>
						<v-divider></v-divider>
						<v-row>
							<v-col cols="12" align="right">
								<v-btn
									depressed
									class="primary mx-1 my-1"
									link
									:to="`/users/${userId}/update`"
									v-if="currentUser.role == 0"
								>
									<v-icon>edit</v-icon>
									ဒေတာပြင်ရန်
								</v-btn>
							</v-col>
						</v-row>
						<v-card-text class="mt-3">
							<v-row>
								<v-col cols="12" md="6">
									<h4>အသုံးပြုသူအကြောင်း</h4>
									<v-row>
										<v-col cols="12" align="center">
											<img
												v-img
												:src="
													user.profile
														? user.profile
														: require('@/assets/placeholder.png')
												"
												width="100"
												height="100"
												class="my-2"
												style="border: 1px solid #ddd"
											/>
										</v-col>
										<v-col cols="3" align="left"> အမည် </v-col>
										<v-col cols="9">: {{ user.username }} </v-col>
										<v-col cols="3" align="left"> ဖုန်းနံပါတ် </v-col>
										<v-col cols="9">: {{ user.phoneNumber }} </v-col>
										<v-col cols="3" align="left"> အိုင်ဒီ </v-col>
										<v-col cols="9">: {{ user.tlId }} </v-col>
									</v-row>
								</v-col>
								<v-col cols="12" md="6">
									<h4 class="mb-5">အသုံးပြုသူဒေတာ</h4>
									<v-row>
										<v-col cols="6" align="center">
											<img
												v-img
												:src="
													user.nrcFront
														? user.nrcFront
														: require('@/assets/placeholder.png')
												"
												width="150"
												height="100"
												style="border: 1px solid #ddd"
											/>
										</v-col>
										<v-col cols="6" align="center">
											<img
												v-img
												:src="
													user.nrcBack
														? user.nrcBack
														: require('@/assets/placeholder.png')
												"
												width="150"
												height="100"
												style="border: 1px solid #ddd"
											/>
										</v-col>
										<v-col cols="3" align="left"> မှတ်ပုံတင်နံပါတ် </v-col>
										<v-col cols="9">: {{ user.nrcNumber }} </v-col>
										<v-col cols="3" align="left"> လိပ်စာ </v-col>
										<v-col cols="9">: {{ user.address }} </v-col>
									</v-row>
								</v-col>
							</v-row>
							<v-divider class="my-5"></v-divider>
							<v-row>
								<v-col cols="12" md="4" align="center">
									<h4>စုစုပေါင်းရထားသော Golds</h4>
									<h1 class="display my-10">{{ user.points }}</h1>
								</v-col>
								<v-col cols="12" md="8">
									<h4 class="my-3">Golds ဝင်ထားသောမှတ်တမ်းများ</h4>
									<v-card>
										<v-simple-table>
											<template v-slot:default>
												<thead class="grey lighten-3">
													<tr>
														<th class="text-left">နေ့</th>
														<th class="text-left">ငွေပမာဏ</th>
														<th class="text-left">ပစ္စည်းအမျိုးအစား</th>
														<th class="text-left">Golds</th>
														<th class="text-left">ရရှိမှုနည်းလမ်း</th>
													</tr>
												</thead>
												<tbody v-if="pointRecords">
													<tr
														v-for="record in pointRecords"
														:key="record._id"
													>
														<td>
															{{
																new Date(
																	record.createdAt
																).toLocaleDateString()
															}}
														</td>
														<td>
															{{
																record.boughtAmount
																	? record.boughtAmount
																	: "-"
															}}
														</td>
														<td>
															{{
																record.boughtCategory
																	? record.boughtCategory
																	: "-"
															}}
														</td>
														<td>{{ record.points }}</td>
														<td>{{ getRecordType(record.type) }}</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
									<v-divider></v-divider>
									<h4 class="my-3">Golds အသုံးပြုထားသော မှတ်တမ်းများ</h4>
									<v-card>
										<v-simple-table>
											<template v-slot:default>
												<thead class="grey lighten-3">
													<tr>
														<th class="text-left">နေ့</th>
														<th class="text-left">Golds</th>
														<th class="text-left">ပစ္စည်း</th>
														<th class="text-left">လုပ်ဆောင်မှု</th>
													</tr>
												</thead>
												<tbody v-if="userUsedRecords">
													<tr
														v-for="record in userUsedRecords"
														:key="record._id"
													>
														<td>
															{{
																new Date(
																	record.createdAt
																).toLocaleDateString()
															}}
														</td>
														<td>{{ record.points }}</td>
														<td>{{ record.item.title }}</td>
														<td>{{ record.status }}</td>
													</tr>
												</tbody>
											</template>
										</v-simple-table>
									</v-card>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<!-- Dialogs -->
		<!-- Point Add Dialog -->
		<v-dialog v-model="pointAddDialog" persistent max-width="330">
			<v-card>
				<v-card-title> Golds ထည့်ပေးရန် </v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-3">
					ထည့်ပေးလိုသော Golds ပမာဏကို ရိုက်ထည့်ပါ
					<v-text-field
						class="mt-3"
						label="Golds ပမာဏ"
						outlined
						v-model="points"
					></v-text-field>
					<v-text-field
						class="mt-3"
						label="မှတ်ချက်"
						outlined
						v-model="note"
					></v-text-field>
					<v-text-field
						class="mt-3"
						label="လုပ်ဆောင်သူအမည်"
						outlined
						v-model="staffName"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-btn color="red" dark @click="pointAddDialog = false"> မလုပ်တော့ပါ </v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						dark
						@click="insertPoint"
						:loading="pointAddLoading"
					>
						ထည့်ပေးမည်
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- / Point Add Dialog -->
		<!-- Birthday Dialog -->
		<v-dialog v-model="birthdayDialog" persistent max-width="330">
			<v-card>
				<v-card-title> မွေးနေ့လက်ဆောင်ပေးရန် </v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-3">
					ထည့်ပေးလိုသော Golds ပမာဏကို ရိုက်ထည့်ပါ
					<v-text-field
						class="mt-3"
						label="Golds ပမာဏ"
						outlined
						v-model="points"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-btn color="red" dark @click="birthdayDialog = false"> မလုပ်တော့ပါ </v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						dark
						@click="giveBirthdayPresent"
						:loading="birthdayLoading"
					>
						ထည့်ပေးမည်
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Birthday Dialog -->
		<!-- Chinese New Year Dialog -->
		<v-dialog v-model="chineseNewYearDialog" persistent max-width="330">
			<v-card>
				<v-card-title> နှစ်သစ်ကူးလက်ဆောင်ပေးမည် </v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-3">
					ထည့်ပေးလိုသော Golds ပမာဏကို ရိုက်ထည့်ပါ
					<v-text-field
						class="mt-3"
						label="Golds ပမာဏ"
						outlined
						v-model="points"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-btn color="red" dark @click="chineseNewYearDialog = false">
						မလုပ်တော့ပါ
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						dark
						@click="giveChineseNewYearPresent"
						:loading="chinesenewyearLoading"
					>
						ထည့်ပေးမည်
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Birthday Dialog -->
		<!-- Price Add Dialog -->
		<v-dialog v-model="priceAddDialog" persistent max-width="330">
			<v-card>
				<v-card-title> ဈေးနှုန်းနှင့် ထည့်ပေးရန် </v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-3">
					ဝယ်ယူသွားသော ဈေးနှုန်းကိုရိုက်ထည့်ပါ
					<v-text-field
						class="mt-3"
						label="ဝယ်ယူသွားသော ပမာဏ"
						outlined
						v-model="price"
					></v-text-field>
					<v-select
						label="ဝယ်ယူသွားသော အမျိုးအစား"
						:items="boughtCategories"
						v-model="boughtCategory"
						outlined
					>
					</v-select>
					<v-text-field
						class="mt-3"
						label="အလေးချိန်"
						outlined
						v-model="weight"
					></v-text-field>
					<v-text-field
						class="mt-3"
						label="ဘောက်ချာနံပါတ်"
						outlined
						v-model="voucherNumber"
					></v-text-field>
					<v-text-field
						class="mt-3"
						label="မှတ်ချက်"
						outlined
						v-model="note"
					></v-text-field>
					<v-text-field
						class="mt-3"
						label="လုပ်ဆောင်သူအမည်"
						outlined
						v-model="staffName"
					></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-btn color="red" dark @click="priceAddDialog = false"> မလုပ်တော့ပါ </v-btn>
					<v-spacer></v-spacer>
					<v-btn
						color="green darken-1"
						dark
						@click="insertWithPrice"
						:loading="priceAddLoading"
					>
						ထည့်ပေးမည်
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- / Price Add Dialog -->
		<!-- / Dialogs -->
	</div>
</template>
<script>
import User from "../../api/model/User"
import Admin from "../../api/model/Admin"
import PointRecordManager from "../../api/model/PointRecordManager"
import RedeemRequestManager from "../../api/model/RedeemRequestManager"
export default {
	name: "UserDetails",
	data: function () {
		return {
			userId: this.$route.params.userId,
			loading: false,
			user: null,
			pointRecords: [],
			userUsedRecords: [],
			points: "",
			boughtCategories: ["Gold", "Diamond", "White Gold"],
			boughtCategory: "",
			weight: "",
			voucherNumber: "",
			note: "",
			pointAddDialog: false,
			pointAddLoading: false,
			birthdayDialog: false,
			chineseNewYearDialog: false,
			birthdayLoading: false,
			chinesenewyearLoading: false,
			price: "",
			staffName: "",
			priceAddDialog: false,
			priceAddLoading: false,
		}
	},
	methods: {
		getRecordType: function (key) {
			switch (key) {
				case "in":
					return "(ထည့်ပေးခြင်း)"
				case "buy":
					return "(ဈေးဝယ်ခြင်း)"
				case "birthday":
					return "မွေးနေ့လက်ဆောင်"
			}
		},
		getUserData: async function () {
			try {
				let result = await User.getInfo(this.userId, this.token)
				this.user = result
			} catch (error) {
				console.log("Error : ", error)
			}
		},
		getUserPointsRecord: async function () {
			try {
				let result = await PointRecordManager.getRecordsWithUser(this.userId, this.token)
				this.pointRecords = result
				console.log(result)
			} catch (error) {
				console.log("Error : ", error)
			}
		},
		giveBirthdayPresent: async function () {
			this.birthdayLoading = true
			let birthdayObj = {
				to: this.userId,
				receiver: {
					username: this.user.username,
					profile: this.user.profile,
				},
				amount: 0,
				points: this.points,
				sender: {
					username: this.adminName,
					profile: null,
				},
			}

			try {
				await Admin.sendBirthdayCard(this.currentUser._id, birthdayObj, this.token)
				this.birthdayDialog = false
				this.birthdayLoading = false
			} catch (error) {
				this.birthdayLoading = false
			}
		},
		giveChineseNewYearPresent: async function () {
			this.chinesenewyearLoading = true
			let newYearObj = {
				to: this.userId,
				receiver: {
					username: this.user.username,
					profile: this.user.profile,
				},
				amount: 0,
				points: this.points,
				sender: {
					username: this.adminName,
					profile: null,
				},
			}

			try {
				await Admin.sendChineseNewYearCard(this.currentUser._id, newYearObj, this.token)
				this.chineseNewYearDialog = false
				this.chinesenewyearLoading = false
			} catch (error) {
				this.chinesenewyearLoading = false
			}
		},
		insertPoint: async function () {
			this.pointAddLoading = true
			let admin = {
				name: this.staffName ? this.staffName : this.adminName,
			}
			try {
				// Insert point
				await User.insertPoint(
					this.userId,
					parseInt(this.points),
					admin,
					this.note,
					this.token
				)

				// Update user datas
				this.getUserData()
				this.getUserPointsRecord()

				// Clear inputs
				this.points = ""
				this.note = ""
				this.staffName = ""
			} catch (error) {
				console.log("Error : ", error)
			}
			this.pointAddLoading = false
			this.pointAddDialog = false
		},
		insertWithPrice: async function () {
			this.priceAddLoading = true
			let body = {
				admin: {
					name: this.adminName,
				},
				boughtAmount: parseInt(this.price),
				boughtCategory: this.boughtCategory,
				weight: this.weight,
				voucherNumber: this.voucherNumber,
				note: this.note,
			}
			try {
				// Insert with price
				await User.insertPointWithBought(this.userId, body, this.token)

				// Update user datas
				this.getUserData()
				this.getUserPointsRecord()

				// Clear inputs
				this.price = ""
				this.note = ""
			} catch (error) {
				console.log("Error : ", error)
			}
			this.priceAddLoading = false
			this.priceAddDialog = false
		},
		getUserUsedRecord: async function () {
			try {
				let result = await RedeemRequestManager.getWithUserId(this.userId, this.token)
				console.log(result)
				this.userUsedRecords = result
			} catch (error) {
				console.log("Unable to load used point records", error)
			}
		},
	},
	created: function () {
		this.getUserData()
		this.getUserPointsRecord()
		this.getUserUsedRecord()
	},
	computed: {
		token: function () {
			return this.$store.state.user.token
		},
		currentUser: function () {
			return this.$store.state.user
		},
		adminName: function () {
			return this.$store.state.user.name
		},
	},
}
</script>
