import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        dark: "#333",
        snow: "#efefef",
        // primary: "#9ac3d9",
        primary: "#152D53",
        secondary: "#E7D6B5",
      },
    },
  },
})
