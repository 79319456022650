<template>
  <div class="giftCard-edit">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="mt-8">
            <v-card-title class="dark--text subtitle-1 font-weight-bold">
              <v-btn
                depressed
                rounded
                class="secondary mx-2"
                dark
                @click="() => $router.go(-1)"
              >
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              ပြင်ဆင်ရန်
              <v-spacer></v-spacer>
              <v-btn
                rounded
                depressed
                class="primary"
                @click="updateData"
                large
                dark
                :loading="loading"
              >
                <v-icon>save</v-icon>
                သိမ်းမည်
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
              <v-card-text class="mt-3">
             
              <v-row>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    ပမာဏ -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="amount"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    ပွိုင့်များ -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="points"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import GiftCard from "../../api/model/GiftCard"
export default {
  name: "GiftCardEdit",
  data: function () {
    return {
      giftCardId: this.$route.params.giftCardId,
      loading: false,
      amount :null,
      points : null,
    };
  },
  methods: {
    updateData: async function () {
      this.loading = true;
      // Obj to update
      var giftCardObj = {
        amount : this.amount,
        points : this.points
      };
      // Check if password should include or not
      //if (!this.newPassword) delete itemObj["password"];
      try {
        await GiftCard.updateInfo(this.giftCardId, giftCardObj,this.token);
        this.$router.push("/giftCard");
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot update giftCard !");
      }
    },
    getGiftCardData: async function () {
      try {
        let result = await GiftCard.getInfo(this.giftCardId,this.token);
        console.log(result)
        // Assigning
        this.amount = result.amount;
        this.points = result.points       
      } catch (err) {
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot get giftCard data !");
      }
    },
  },
  created: async function () {
    // get the data of current 
   await this.getGiftCardData()
  },
  
  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
  
};
</script>