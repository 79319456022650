import { GET, POST } from "../fetch";
const root = "redeem-manager";

export default {
  totalRequest: async (token) => {
    try {
      let response = await GET(`/${root}/get-total`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  requestWithRange: async (skip, limit, token) => {
    let body = { skip, limit };
    try {
      let response = await POST(`/${root}/request-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  searchRequest: async (searchText, token) => {
    try {
      let response = await GET(`/${root}/search-request/${searchText}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getWithUserId: async (userId, token) => {
    try {
      let response = await GET(`/${root}/get-data-with-user/${userId}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithDate: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-data-with-date`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithWeek: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-data-with-week`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithMonth: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-data-with-month`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithDateRange: async function (startDate, endDate, token) {
    let body = { startDate, endDate };
    try {
      let response = await POST(`/${root}/get-data-with-date-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
