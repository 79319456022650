import { GET,POST } from "../fetch";
const root ="news-manager"

export default {
    createNews : async (news,token) => {
        try {
            let response = await POST(`/${root}/create-news`,news,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    totalNews : async (token) => {
        try {
            let response = await  GET(`/${root}/total-news`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    newsWithRange : async (skip,limit,token) =>{
        let body = {skip,limit}
        try {
            let response = await POST(`/${root}/news-with-range`,body,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    searchNews : async (searchText,token) =>{
        try {
            let response = await GET(`/${root}/search-news/${searchText}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
} 