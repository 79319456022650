import { GET, PUT, DELETE, POST } from "../fetch";
const root = "admin";

export default {
  getInfo: async function (id, token) {
    try {
      let response = await GET(`/${root}/get-info/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  updateInfo: async function (id, body, token) {
    try {
      let response = await PUT(`/${root}/update-info/${id}`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  delete: async function (id, token) {
    try {
      let response = await DELETE(`/${root}/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  sendBirthdayCard: async function (id, body, token) {
    try {
      let response = await POST(
        `/${root}/send-birthday-card/${id}`,
        body,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  sendChineseNewYearCard: async function (id, body, token) {
    try {
      let response = await POST(
        `/${root}/send-chinese-newyear-card/${id}`,
        body,
        token
      )
      return response
    } catch (error) {
      throw new Error(error)
    }
  },

  acceptRequest: async function (id, requestId, body, token) {
    try {
      let response = await PUT(
        `/${root}/accept-request/${id}/${requestId}`,
        body,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  rejectRequest: async function (id, requestId, body, token) {
    try {
      let response = await PUT(
        `/${root}/reject-request/${id}/${requestId}`,
        body,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
