import { GET,POST } from "../fetch";
const root = "gift-card-manager"

export default {

    createGiftCard :async (item,token) => {
        try {
            let response = await POST(`/${root}/create-giftcard`,item,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    getTotal : async (token) => {
        try {
            let response = await GET(`/${root}/get-total`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    giftCardWithRange : async (skip,limit,token )=> {
        let body = {skip,limit}
        try {
            let response = await POST(`/${root}/get-giftcard-with-range`,body,token)
            return response 
        } catch (error) {
            throw new Error(error)
        }
    },
    
    searchGiftCard :async (searchText,token) => {
        try {
            let response = await GET(`/${root}/search-gift-card/${searchText}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
}