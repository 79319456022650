import { GET, PUT, DELETE } from "../fetch";
const root = "user";

export default {
  getInfo: async function (id, token) {
    try {
      let response = await GET(`/${root}/get-info/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  updateInfo: async function (id, body, token) {
    try {
      let response = await PUT(`/${root}/update-info/${id}`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  delete: async function (id, token) {
    try {
      let response = await DELETE(`/${root}/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  giveBirthdayPresent: async function (id, points, admin, note, token) {
    let body = { points, admin, note };
    try {
      let response = await PUT(
        `/${root}/give-birthday-present/${id}`,
        body,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  insertPoint: async function (id, points, admin, note, token) {
    let body = { points, admin, note };
    try {
      let response = await PUT(`/${root}/insert-point/${id}`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  insertPointWithBought: async function (id, body, token) {
    // let body = { boughtAmount, admin, note };
    try {
      let response = await PUT(
        `/${root}/insert-pt-with-bought/${id}`,
        body,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  requestToRedeem: async function (id, token) {
    try {
      let response = await PUT(`/${root}/request-to-redeem/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
