import { GET, POST } from "../fetch"
const root = "gift-card-records-manager"

export default {
	getTotalGiftcardsFromAdmin: async function (token) {
		try {
			let response = await GET(`/${root}/get-total-from-admin`, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getTotalChineseNewyearCards: async function (token) {
		try {
			let response = await GET(`/${root}/get-total-chinese-newyear-cards-from-admin`, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getGiftcardsWithRangeFromAdmin: async function (skip, limit, token) {
		let body = { skip, limit }
		try {
			let response = await POST(`/${root}/get-giftcard-with-range-from-admin`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getChineseNewyearCardsWithRangeFromAdmin: async function (skip, limit, token) {
		let body = { skip, limit }
    try {
      let response = await POST(`/${root}/get-chinese-newyear-cards-with-range-from-admin`, body, token)
      return response
    } catch (error) {
      throw new Error(error)
    }
	},
	getGiftcardsGoingToExpire: async function (token) {
		try {
			let response = await GET(`/${root}/get-giftcards-going-to-expire`, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getDataWithDate: async function (date, token) {
		let body = { date }
		try {
			let response = await POST(`/${root}/get-data-with-date`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getDataWithWeek: async function (date, token) {
		let body = { date }
		try {
			let response = await POST(`/${root}/get-data-with-week`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getDataWithMonth: async function (date, token) {
		let body = { date }
		try {
			let response = await POST(`/${root}/get-data-with-month`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getDataWithDateRange: async function (startDate, endDate, token) {
		let body = { startDate, endDate }
		try {
			let response = await POST(`/${root}/get-data-with-date-range`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
  getNewyearDataWithDate: async function (date, token) {
		let body = { date }
		try {
			let response = await POST(`/${root}/get-newyear-data-with-date`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getNewyearDataWithWeek: async function (date, token) {
		let body = { date }
		try {
			let response = await POST(`/${root}/get-newyear-data-with-week`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getNewyearDataWithMonth: async function (date, token) {
		let body = { date }
		try {
			let response = await POST(`/${root}/get-newyear-data-with-month`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
	getNewyearDataWithDateRange: async function (startDate, endDate, token) {
		let body = { startDate, endDate }
		try {
			let response = await POST(`/${root}/get-newyear-data-with-date-range`, body, token)
			return response
		} catch (error) {
			throw new Error(error)
		}
	},
}
