<template>
  <div class="setting-add">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="mt-8">
            <v-card-title class="dark--text subtitle-1 font-weight-bold">
              <v-btn
                depressed
                rounded
                class="secondary mx-2"
                dark
                @click="() => $router.go(-1)"
              >
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              {{ "အသစ်ဖွင့်ရန်" }}
              <v-spacer></v-spacer>
              <v-btn
                rounded
                depressed
                class="primary"
                @click="createItem()"
                large
                dark
                :loading="loading"
              >
                <v-icon>save</v-icon>
                {{ "သိမ်းမည်" }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-3">
              <v-row>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    {{ "ရာခိုင်နှုန်း" }} -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="pointPercent"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    {{ "Gold တန်ဖိုး" }} -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="pointValue"
                    type="number"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    {{ "SMS Poh Token" }} -
                  </p>
                  <v-textarea
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="smsPohToken"
                    type="text"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import System from "../../api/model/System";
export default {
  name: "SettingAdd",
  data: function () {
    return {
      loading: false,
      pointPercent: null,
      smsPohToken: null,
      pointValue: null,
    };
  },
  methods: {
    createItem: async function () {
      this.loading = true;
      var setting = {
        pointPercent: this.pointPercent,
        smsPohToken: this.smsPohToken,
        pointValue: this.pointValue,
      };
      try {
        await System.createSetting(setting, this.token);
        this.$router.push("/settings");
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot Add Discount !");
      }
    },
  },
  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
};
</script>
