import { POST } from "../fetch"
const root = "notification"

export default {

    sendNotification: async (notification, token) => {
        try {
            let response = await POST(`/${root}/send-notification`, notification, token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }

}