<template>
  <div class="nav-bar">
    <v-app-bar-nav-icon @click="drawer = !drawer" dark></v-app-bar-nav-icon>
    <v-navigation-drawer v-model="drawer" app light absolute temporary>
      <v-list nav>
        <v-list-item-group>
          <template v-for="menu in menus">
            <v-list-item
              :to="menu.path"
              :key="menu.path"
              active-class="primary lighten-4 white--text"
              class="py-1"
              v-if="currentRole <= menu.role"
            >
              <v-list-item-icon>
                <v-icon>{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-bold">
                {{ menu.title }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Navbar",
  data: function () {
    return {
      drawer: false,
      menus: [
        {
          title: "ပင်မစာမျက်နှာ",
          icon: "dashboard",
          path: "/dashboard",
          role: 3,
        },
        {
          title: "Goldပေးထားမှုများ",
          icon: "add_reaction",
          path: "/point-records",
          role: 2,
        },
        {
          title: "မွေးနေ့လက်ဆာင် ပေးထားမှုများ",
          icon: "cake",
          path: "/birthday-cards",
          role: 3,
        },
        {
          title: "Seasonal Gifts ပေးထားမှုများ",
          icon: "redeem",
          path: "/chinese-newyear-cards",
          role: 3
        },
        {
          title: "လဲလှယ်မှုများ",
          icon: "sync",
          path: "/requests",
          role: 3,
        },
        {
          title: "အသုံးပြုသူများ",
          icon: "people",
          path: "/users",
          role: 3,
        },
        {
          title: "လက်ဆောင်ပစ္စည်းများ",
          icon: "redeem",
          path: "/items",
          role: 1,
        },
        {
          title: "လျော့ဈေးများ",
          icon: "percent",
          path: "/discounts",
          role: 1,
        },
        {
          title: "သတင်းများ",
          icon: "newspaper",
          path: "/news",
          role: 3,
        },
        {
          title: "ပရိုမိုးရှင်းများ",
          icon: "celebration",
          path: "/promotions",
          role: 1,
        },
        {
          title: "ရွှေဈေးများ",
          icon: "bar_chart",
          path: "/goldPrice",
          role: 1,
        },
        {
          title: "လက်ဆောင်ကတ်များ",
          icon: "credit_card",
          path: "/giftCard",
          role: 1,
        },
        {
          title: "အက်ဒမင်များ",
          icon: "admin_panel_settings",
          path: "/admins",
          role: 1,
        },
        {
          title: "Notification ပို့မည်",
          icon: "notifications",
          path: "/send-notifications",
          role: 1
        },
        {
          title: "ဆက်တင်များ",
          icon: "settings",
          path: "/settings",
          role: 1,
        },
      ],
    };
  },
  computed: {
    currentRole: function () {
      return this.$store.state.user.role;
    },
  },
};
</script>
