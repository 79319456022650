<template>
  <div class="point-records">
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- List of students -->
          <v-data-table
            :page="page"
            :pageCount="numberOfPages"
            :search="search"
            :loading="loading"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :server-items-length="totalCount"
            :options.sync="options"
            :items="records"
            class="elevation-1 dark--text text-caption px-5 mt-10"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="9"> </v-col>
                <v-col cols="3">
                  <v-btn
                    depressed
                    rounded
                    block
                    class="primary mx-2"
                    dark
                    @click="handleReport"
                  >
                    <v-icon>summarize</v-icon>
                    Report ထုတ်မည်
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <!-- Slot for image -->
            <template v-slot:[`item.receiver.profile`]="{ item }">
              <v-img
                :src="
                  item.profile ? item.profile : require('@/assets/LogoSm.jpg')
                "
                width="60"
                height="60"
                class="my-2"
              ></v-img>
            </template>
            <!-- / Slot for image -->
            <!-- Slot for date -->
            <template v-slot:[`item.createdAt`]="{ item }">
              <p>{{ new Date(item.createdAt).toLocaleDateString() }}</p>
            </template>
            <!-- / Slot for date -->
            <template v-slot:no-data>
              <v-row>
                <v-col cols="12" align="center" class="dark--text my-15">
                  <v-icon>info</v-icon> {{ "no_data" }}
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { ExportToCsv } from "export-to-csv";
import GiftcardRecordsManager from "../../api/model/GiftcardRecordsManager";
export default {
  name: "BirthdayCardsList",
  data: function () {
    return {
      page: 1,
      totalCount: null,
      numberOfPages: null,
      itemsPerPage: 10,
      loading: false,
      options: {},
      search: null,
      headers: [
        { text: "လက်ခံသူ", value: "receiver.username" },
        { text: "လက်ခံသူပုံ", value: "receiver.profile" },
        { text: "လုပ်ဆောင်သူ", value: "sender.username" },
        { text: "Golds", value: "points" },
        { text: "နေ့", value: "createdAt" },
      ],
      records: [],
    };
  },
  methods: {
    handleReport: function () {
      this.$router.push("/birthday-cards-report")
    },
    exportRecordData: function () {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Royal Tahlee Gold Club Birthdaycard Records",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Royal Tahlee Gold Club Birthdaycard Records",
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(this.getExportData());
    },
    getExportData: function () {
      let exportData = [];
      this.records.map((data) => {
        exportData.push({
          Date: data.createdAt,
          Receiver: data.receiver.username,
          ActionedBy: data.sender.username,
          Golds: data.points,
        });
      });
      return exportData;
    },
    readDataWithLimit: async function (page, itemsPerPage) {
      // Table is loading
      this.loading = true;
      try {
        // First get the server item length
        var result = await GiftcardRecordsManager.getTotalGiftcardsFromAdmin(
          this.token
        );
        this.totalCount = result;
        // Calculate skip and limit
        var skip = this.totalCount - itemsPerPage * page;
        var limit;
        if (skip < 0) {
          skip = 0;
          limit = itemsPerPage + skip;
        } else {
          limit = itemsPerPage;
        }

        // Read data with limit
        result = await GiftcardRecordsManager.getGiftcardsWithRangeFromAdmin(
          skip,
          limit,
          this.token
        );
        this.records = result;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot fetch request data !");
      }
    },
  },
  watch: {
    // Do this function everytime the options change
    options: function (options) {
      this.readDataWithLimit(options.page, options.itemsPerPage);
    },
  },

  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
};
</script>
<style scoped>
.name:hover {
  cursor: pointer;
}
</style>
