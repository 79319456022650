var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"giftCard"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 dark--text text-caption px-5 mt-10",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalCount,"options":_vm.options,"items":_vm.giftCard},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}}),_c('v-col',{attrs:{"cols":"6","align":"right"}},[_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","depressed":"","link":"","to":'/giftCard-add',"large":""}},[_c('v-icon',[_vm._v("add")]),_vm._v(" အသစ်ဖွင့်မည် ")],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"dark--text my-15",attrs:{"cols":"12","align":"center"}},[_c('v-icon',[_vm._v("info")]),_vm._v(" "+_vm._s("no_data")+" ")],1)],1)]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editItem(item._id)}}},[_c('v-icon',[_vm._v("edit")])],1)]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function () {
                  _vm.toDelete = item._id;
                  _vm.dialog = true;
                }}},[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)}),_c('confirm-box',{attrs:{"dialog":_vm.dialog},on:{"closeDialog":function () { return (_vm.dialog = false); },"deleteItem":_vm.deleteItem}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }