<template>
  <div class="giftCard">
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- List of students -->
          <v-data-table
            :page="page"
            :pageCount="numberOfPages"
            :search="search"
            :loading="loading"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :server-items-length="totalCount"
            :options.sync="options"
            :items="giftCard"
            class="elevation-1 dark--text text-caption px-5 mt-10"
          >
            <!-- Top of table -->
            <template v-slot:top>
              <v-row>
                <v-col cols="6">
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    rounded
                    depressed
                    class="primary"
                    link
                    :to="'/giftCard-add'"
                    large
                  >
                    <v-icon>add</v-icon>
                    အသစ်ဖွင့်မည်
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <!-- End of top -->
            <template v-slot:no-data>
              <v-row>
                <v-col cols="12" align="center" class="dark--text my-15">
                  <v-icon>info</v-icon> {{ "no_data" }}
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-btn small icon @click="editItem(item._id)"
                ><v-icon>edit</v-icon></v-btn
              >
            </template>
            <!-- slot for edit item -->

            <!-- Slot for delete -->
           <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                small
                icon
                @click="
                  () => {
                    toDelete = item._id;
                    dialog = true;
                  }
                "
                ><v-icon>delete</v-icon></v-btn
              >
            </template>
            <!-- / delete Slot -->
        
          </v-data-table>
            <confirm-box
            :dialog="dialog"
            @closeDialog="() => (dialog = false)"
            @deleteItem="deleteItem"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import GiftCard from "../../api/model/GiftCard"
import GiftCardManager from "../../api/model/GiftCardManager"
import ConfirmBox from "../../components/Util/ConfrimBox.vue"
export default {
  name: "GiftCardList",
  components: {ConfirmBox},
  data: function () {
    return {
      page: 1,
      totalCount: null,
      numberOfPages: null,
      itemsPerPage: 10,
      loading: false,
      options: {},
      search: null,
      headers: [
        { text: "ပမာဏ",value:"amount"},
        { text: "ပွိူင့်များ",value :"points"},
        { text: "ပြင်ဆင်မည်",value : "edit"},
        { text: "ဖျက်မည်", value: "delete" },
      ],
      giftCard: [],
      dialog:false,
      toDelete:null,
    };
  },
  methods: {
    readDataWithLimit: async function (page, itemsPerPage) {
      // Table is loading
      this.loading = true;
      try {
        // First get the server item length
        var result = await GiftCardManager.getTotal(this.token);
        this.totalCount = result;
        console.log(this.totalCount)
        // Calculate skip and limit
        var skip = this.totalCount - itemsPerPage * page;
        var limit;
        if (skip < 0) {
          skip = 0;
          limit = itemsPerPage + skip;
        } else {
          limit = itemsPerPage;
        }

        // Read data with limit
        result = await GiftCardManager.giftCardWithRange(skip,limit,this.token);
        this.giftCard = result;
        console.log(this.giftCard)
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot fetch student data !");
      }
    },
    searchItem: async function () {
      this.loading = true;
      if (!this.search) {
        // if search text is null reload with default
        this.readDataWithLimit(1, this.itemsPerPage);
      } else {
        try {
          this.giftCard = await GiftCardManager.searchGiftCard(this.search,this.token);
          this.totalCount = this.giftCard.length;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log("Error : ", err);
          this.$store.dispatch("MAKE_ERROR", "Unable to search !");
        }
      }
    },
    deleteItem :async function (){
    this.dialog= false;
    try {
      await GiftCard.delete(this.toDelete,this.token)
      console.log(this.toDelete)
    } catch (error) {
      this.dialog = false;
      console.log("Error:",error)
      this.$store.dispatch("MAKE_ERROR","Unable to delete")
    }
    this.readDataWithLimit(this.options.page,this.options.itemsPerPage)
  },
    editItem :function(giftCardId){
        this.$router.push("/giftCard/"+giftCardId)
    },
    
  },
  watch: {
    // Do this function everytime the options change
    options: function (options) {
      this.readDataWithLimit(options.page, options.itemsPerPage);
    },
  },

  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
};
</script>
<style scoped>
.name:hover {
  cursor: pointer;
}
</style>