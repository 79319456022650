<template>
  <div class="news-add">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card class="mt-8">
            <v-card-title class="dark--text subtitle-1 font-weight-bold">
              <v-btn
                depressed
                rounded
                class="secondary mx-2"
                dark
                @click="() => $router.go(-1)"
              >
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              {{ "အသစ်ဖွင့်မည်" }}
              <v-spacer></v-spacer>
              <v-btn
                rounded
                depressed
                class="primary"
                @click="createItem()"
                large
                dark
                :loading="loading"
              >
                <v-icon>save</v-icon>
                {{ "သိမ်းမည်" }}
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-3">
             
              <v-row>
               
                <v-col cols="12" align="center">
                  <v-img
                    width="500"
                    height="300"
                    style="border: 1px solid #ddd"
                    :src="photo"
                  >
                  </v-img>
                  <v-btn
                    class="primary my-5"
                    small
                    rounded
                    depressed
                    :loading="isSelecting"
                    @click="onFileSelect"
                  >
                    <v-icon>cloud_upload</v-icon>
                    ပုံတင်မည်
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="onFileChange"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    {{ "ခေါင်းစဥ်" }} -
                  </p>
                  <v-text-field
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="title"
                    type="text"
                  >
                  </v-text-field>
                </v-col>
                 <v-col cols="12" md="4">
                  <p class="dark--text body-2 font-weight-bold">
                    {{ "ဖော်ပြချက်" }} -
                  </p>
                  <v-textarea
                    solo
                    background-color="grey lighten-2"
                    flat
                    rounded
                    class="dark--text"
                    v-model="description"
                    type="text"
                  >
                  </v-textarea>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import File from "../../api/model/File"
import NewsManager from "../../api/model/NewsManager"
export default {
  name: "NewsAdd",
  data: function () {
    return {
      loading: false,
      isSelecting: false,
      selectedFile: null,
      photo : null,
      title : null,
      description :null,
    };
  },
  methods: {
    createItem: async function () {
      this.loading = true;
      var news = {
        photo: this.photo,
        title : this.title,
        description : this.description

        
      };
      try {
        await NewsManager.createNews(news,this.token);
        this.$router.push("/news");
      } catch (err) {
        this.loading = false;
        this.$store.dispatch("MAKE_ERROR", "Cannot Add News !");
      }
    },
    onFileSelect: function () {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );
      this.$refs.uploader.click();
    },
    onFileChange: async function (e) {
      // When the upload file is changed
      this.selectedFile = e.target.files[0];
      // Try uploading the photo
      try {
        let result = await File.uploadPhoto(this.selectedFile, "news",this.token);
        this.photo = result.url;
        this.isSelecting = false
      } catch (error) {
        console.log("Error : ", error);
      }
    },

  },
  computed:{
    token:function(){
      return this.$store.state.user.token
    }
  }
};
</script>