import { GET, POST } from "../fetch";
const root = "gold-price-manager";

export default {
  createGoldPrice: async (goldPrice, token) => {
    try {
      let response = await POST(`/${root}/create-gold-price`, goldPrice, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  totalGoldPrice: async (token) => {
    try {
      let response = await GET(`/${root}/total-gold-price`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  goldPriceWithRange: async (skip, limit, token) => {
    let body = { skip, limit };
    try {
      let response = await POST(`/${root}/gold-price-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  searchGoldPrice: async (searchText, token) => {
    try {
      let response = await GET(
        `/${root}/search-gold-price/${searchText}`,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  latestGoldPrice: async (token) => {
    try {
        let response = await GET(`/${root}/get-latest-gold-price`, token)
        return response
    } catch (error) {
        throw new Error(error)
    }
  },
};
