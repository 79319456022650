import { GET,POST } from "../fetch";
const root ="promotion-manager"

export default {
    createPromotion : async (promotion,token) => {
        try {
            let response = await POST(`/${root}/create-promotion`,promotion,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    totalPromotion : async (token) => {
        try {
            let response = await  GET(`/${root}/total-promotion`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    promotionWithRange : async (skip,limit,token) =>{
        let body = {skip,limit}
        try {
            let response = await POST(`/${root}/promotion-with-range`,body,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    searchPromotion : async (searchText,token) =>{
        try {
            let response = await GET(`/${root}/search-promotion/${searchText}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
} 