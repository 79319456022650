<template>
  <div class="point-records">
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- List of students -->
          <v-data-table
            :page="page"
            :pageCount="numberOfPages"
            :search="search"
            :loading="loading"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :server-items-length="totalCount"
            :options.sync="options"
            :items="records"
            class="elevation-1 dark--text text-caption px-5 mt-10"
          >
            <!-- Top of table -->
            <template v-slot:top>
              <v-row>
                <v-col cols="9"> </v-col>
                <v-col cols="3">
                  <v-btn
                    depressed
                    rounded
                    block
                    class="primary mx-2"
                    dark
                    @click="handleReport"
                  >
                    <v-icon>summarize</v-icon>
                    Report ထုတ်မည်
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <!-- Slot for user -->
            <template v-slot:[`item.userId`]="{ item }">
              <p
                @click="$router.push(`/users/${item.userId}`)"
                class="body-2 primary--text font-weight-bold text-decoration-underline name"
              >
                {{ item.userId }}
              </p>
            </template>
            <template v-slot:[`item.boughtCategory`]="{ item }">
              <p>{{ item.boughtCategory ? item.boughtCategory : "-" }}</p>
            </template>
            <!-- Slot For Date -->
            <template v-slot:[`item.createdAt`]="{ item }">
              <p>{{ new Date(item.createdAt).toLocaleDateString() }}</p>
            </template>
            <!-- Slot For Type -->
            <template v-slot:[`item.type`]="{ item }">
              <p>{{ getRecordType(item.type) }}</p>
            </template>
            <!-- End of top -->
            <template v-slot:no-data>
              <v-row>
                <v-col cols="12" align="center" class="dark--text my-15">
                  <v-icon>info</v-icon> {{ "no_data" }}
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { ExportToCsv } from "export-to-csv";
import PointRecordManager from "../../api/model/PointRecordManager";
export default {
  name: "PointRecordList",
  data: function () {
    return {
      page: 1,
      totalCount: null,
      numberOfPages: null,
      itemsPerPage: 10,
      loading: false,
      options: {},
      search: null,
      headers: [
        { text: "အသုံးပြုသူ", value: "userId" },
        { text: "အသုံးပြုသူအမည်", value: "userName" },
        { text: "နေ့", value: "createdAt" },
        { text: "အမျိူးအစား", value: "type" },
        { text: "ငွေပမာဏ", value: "boughtAmount" },
        { text: "ပစ္စည်းအမျိုးအစား", value: "boughtCategory" },
        { text: "အလေးချိန်", value: "weight" },
        { text: "ဘောင်ချာနံပါတ်", value: "voucherNumber" },
        { text: "Golds", value: "points" },
        { text: "လုပ်ဆောင်သူ", value: "admin.name" },
        { text: "မှတ်ချက်", value: "note" },
      ],
      records: [],
    };
  },
  methods: {
    getRecordType: function (key) {
      switch (key) {
        case "in":
          return "ထည့်ပေးခြင်း";
        case "buy":
          return "ဈေးဝယ်ခြင်း";
        case "birthday":
          return "မွေးနေ့လက်ဆောင်";
      }
    },
    handleReport: function () {
      this.$router.push("/point-record-report")
    },
    exportRecordData: function () {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Royal Tahlee Gold Club Point Give Records",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Royal Tahlee Gold Club Point Give Records",
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(this.getExportData());
    },
    getExportData: function () {
      let exportData = [];
      this.records.map((data) => {
        exportData.push({
          Username: data.userName,
          Date: data.createdAt,
          RecordType: data.type,
          BoughtAmount: data.boughtAmount,
          ItemCategory: data.boughtCategory,
          Weight: data.weight,
          VoucherNumber: data.voucherNumber,
          Golds: data.points,
          ActionedBy: data.admin.name,
          Note: data.note,
        });
      });
      return exportData;
    },
    readDataWithLimit: async function (page, itemsPerPage) {
      // Table is loading
      this.loading = true;
      try {
        // First get the server item length
        var result = await PointRecordManager.totalRecords(this.token);
        this.totalCount = result;
        // Calculate skip and limit
        var skip;
        var limit;
        if (itemsPerPage == -1) {
          skip = 0;
          limit = this.totalCount;
        } else {
          skip = this.totalCount - itemsPerPage * page;

          if (skip < 0) {
            skip = 0;
            limit = itemsPerPage + skip;
          } else {
            limit = itemsPerPage;
          }
        }

        // Read data with limit
        result = await PointRecordManager.recordsWithRange(
          skip,
          limit,
          this.token
        );
        this.records = result;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot fetch request data !");
      }
    },
  },
  watch: {
    // Do this function everytime the options change
    options: function (options) {
      this.readDataWithLimit(options.page, options.itemsPerPage);
    },
  },

  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
};
</script>
<style scoped>
.name:hover {
  cursor: pointer;
}
</style>
