var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"requests"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 dark--text text-caption px-5 mt-10",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalCount,"options":_vm.options,"items":_vm.request},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.searchItem.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mx-4 dark--text text-body-2 px-5",attrs:{"placeholder":"Search","clearable":"","solo":"","filled":"","dense":"","flat":"","prepend-icon":"refresh","append-icon":"search","background-color":"grey lighten-3","color":"dark"},on:{"click:append":_vm.searchItem,"click:prepend":function () { return _vm.readDataWithLimit(1, _vm.itemsPerPage); },"click:clear":function($event){return _vm.readDataWithLimit(1, _vm.itemsPerPage)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"primary mx-2",attrs:{"depressed":"","rounded":"","block":"","dark":""},on:{"click":_vm.handleReport}},[_c('v-icon',[_vm._v("summarize")]),_vm._v(" Report ထုတ်မည် ")],1)],1)],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"dark--text my-15",attrs:{"cols":"12","align":"center"}},[_c('v-icon',[_vm._v("info")]),_vm._v(" "+_vm._s("no_data")+" ")],1)],1)]},proxy:true},{key:"item.item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"my-2",attrs:{"src":item.item.photo
                  ? item.item.photo
                  : require('@/assets/LogoSm.jpg'),"width":"60","height":"60"}})]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status == 'pending')?_c('div',[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","color":"green"},on:{"click":function () {
                    _vm.acceptDialog = true;
                    _vm.selectedItem = item._id;
                  }}},[_vm._v(" Accept ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.reject(item._id)}}},[_vm._v(" Reject ")])],1):_vm._e(),(item.status == 'accepted')?_c('v-chip',{staticClass:"primary"},[_vm._v(" Accepted ")]):_vm._e(),(item.status == 'rejected')?_c('v-chip',{staticClass:"error"},[_vm._v(" Rejected ")]):_vm._e()]}},{key:"item.delete",fn:function(ref){
                  var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function () {
                  _vm.toDelete = item._id;
                  _vm.dialog = true;
                }}},[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"330"},model:{value:(_vm.acceptDialog),callback:function ($$v) {_vm.acceptDialog=$$v},expression:"acceptDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Voucher Number ကိုထည့်ပါ")]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-3"},[_c('v-text-field',{staticClass:"mt-4",attrs:{"label":"Voucher နံပါတ်","outlined":""},model:{value:(_vm.voucherNumber),callback:function ($$v) {_vm.voucherNumber=$$v},expression:"voucherNumber"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.acceptDialog = false}}},[_vm._v(" မလုပ်တော့ပါ ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","dark":""},on:{"click":function($event){return _vm.accept(_vm.selectedItem)}}},[_vm._v(" လဲပေးမည် ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }