<template>
	<div class="user-update">
		<v-container v-if="user">
			<v-card>
				<v-card-title class="dark--text subtitle-1 font-weight-bold">
					<v-row class="px-3 py-3">
						<v-col cols="12" md="3">
							<v-row>
								<v-btn
									depressed
									rounded
									class="secondary mx-2"
									dark
									@click="() => $router.go(-1)"
								>
									<v-icon>keyboard_arrow_left</v-icon>
								</v-btn>
								အသုံးပြုသူဒေတာ ပြင်မည်
							</v-row>
						</v-col>
						<v-col cols="12" md="9">
							<v-row justify="end">
								<v-btn
									depressed
									class="primary mx-1 my-1"
									@click="handleUpdateUser"
									:loading="updateLoading"
								>
									<v-icon>save</v-icon>
									သိမ်းမည်
								</v-btn>
							</v-row>
						</v-col>
					</v-row>
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text>
					<v-row>
						<v-col cols="12" md="6">
							<v-text-field
								class="mt-3"
								label="အမည်"
								outlined
								v-model="username"
							></v-text-field>
							<v-text-field
								class="mt-3"
								label="မှတ်ပုံတင်နံပါတ်"
								outlined
								v-model="nrcNumber"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-textarea class="mt-3" label="လိပ်စာ" outlined v-model="address">
							</v-textarea>
						</v-col>
						<v-col cols="12">
							<h4>မွေးနေ့</h4>
						</v-col>
						<v-col cols="2">
							<v-text-field
								class="mt-3"
								label="နေ့"
								outlined
								v-model="birthDate"
								placeholder="dd"
							></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-text-field
								class="mt-3"
								label="လ"
								outlined
								v-model="birthMonth"
								placeholder="mm"
							></v-text-field>
						</v-col>
						<v-col cols="2">
							<v-text-field
								class="mt-3"
								label="နှစ်"
								outlined
								v-model="birthYear"
								placeholder="YYYY"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-container>
	</div>
</template>
<script>
import User from "../../api/model/User"

export default {
	name: "UserUpdate",
	data: function () {
		return {
			userId: this.$route.params.userId,
			loading: false,
			user: null,
			updateLoading: false,
			username: null,
			profile: null,
			nrcNumber: null,
			address: null,
			updateLoaidng: false,
			birthDate: null,
			birthMonth: null,
			birthYear: null,
		}
	},
	methods: {
		handleUpdateUser: async function () {
			this.updateLoading = true

			let body = {
				username: this.username,
				nrcNumber: this.nrcNumber,
				address: this.address,
				birthday: `${this.birthDate}-${this.birthMonth}-${this.birthYear}`,
				birthdayDate: parseInt(this.birthDate),
				birthdayMonth: parseInt(this.birthMonth) - 1,
				isMale: this.user.isMale,
				nrcFront: this.user.nrcFront,
				nrcBack: this.user.nrcBack,
				fromAdmin: true
			}

			try {
				await User.updateInfo(this.userId, body, this.token)
				this.updateLoading = false

				this.$router.go(-1)
			} catch (error) {
				console.log(error)
			}
		},
		getUserData: async function () {
			this.loading = true
			try {
				let result = await User.getInfo(this.userId, this.token)
				this.user = result
				this.username = result.username
				this.profile = result.profile
				this.nrcNumber = result.nrcNumber
				this.address = result.address

				let birthdayString =
					new Date(result.birthday).toLocaleDateString("en-CL") == "Invalid Date"
						? result.birthday
						: new Date(result.birthday).toLocaleDateString("en-IN").replaceAll("/", "-")
				let birthdayArray = birthdayString.split("-")
				this.birthDate = birthdayArray[0]
				this.birthMonth = birthdayArray[1]
				this.birthYear = birthdayArray[2]
			} catch (error) {
				console.log(error)
			}
			this.loading = false
		},
	},
	created: async function () {
		await this.getUserData()
	},
	computed: {
		token: function () {
			return this.$store.state.user.token
		},
	},
}
</script>
