<template>
  <div class="requests">
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- List of students -->
          <v-data-table
            :page="page"
            :pageCount="numberOfPages"
            :search="search"
            :loading="loading"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :server-items-length="totalCount"
            :options.sync="options"
            :items="request"
            class="elevation-1 dark--text text-caption px-5 mt-10"
          >
            <!-- Top of table -->
            <template v-slot:top>
              <v-row>
                <v-col cols="6">
                  <!-- Search Box -->
                  <form v-on:submit.prevent="searchItem">
                    <v-text-field
                      v-model="search"
                      placeholder="Search"
                      class="mx-4 dark--text text-body-2 px-5"
                      clearable
                      solo
                      filled
                      dense
                      flat
                      prepend-icon="refresh"
                      append-icon="search"
                      background-color="grey lighten-3"
                      color="dark"
                      @click:append="searchItem"
                      @click:prepend="() => readDataWithLimit(1, itemsPerPage)"
                      @click:clear="readDataWithLimit(1, itemsPerPage)"
                    >
                    </v-text-field>
                  </form>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="4">
                  <v-btn
                    depressed
                    rounded
                    block
                    class="primary mx-2"
                    dark
                    @click="handleReport"
                  >
                    <v-icon>summarize</v-icon>
                    Report ထုတ်မည်
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <!-- End of top -->
            <template v-slot:no-data>
              <v-row>
                <v-col cols="12" align="center" class="dark--text my-15">
                  <v-icon>info</v-icon> {{ "no_data" }}
                </v-col>
              </v-row>
            </template>
            <!-- Slot for image -->
            <template v-slot:[`item.item.photo`]="{ item }">
              <v-img
                :src="
                  item.item.photo
                    ? item.item.photo
                    : require('@/assets/LogoSm.jpg')
                "
                width="60"
                height="60"
                class="my-2"
              ></v-img>
            </template>
            <!-- / Slot for image -->
            <!-- slot for status -->
            <template v-slot:[`item.status`]="{ item }">
              <div v-if="item.status == 'pending'">
                <v-btn
                  small
                  color="green"
                  @click="
                    () => {
                      acceptDialog = true;
                      selectedItem = item._id;
                    }
                  "
                  class="mx-1"
                >
                  Accept
                </v-btn>
                <v-btn
                  small
                  color="error"
                  @click="reject(item._id)"
                  class="mx-1"
                >
                  Reject
                </v-btn>
              </div>
              <v-chip class="primary" v-if="item.status == 'accepted'">
                Accepted
              </v-chip>
              <v-chip class="error" v-if="item.status == 'rejected'">
                Rejected
              </v-chip>
            </template>
            <!-- /slot for status -->
            <!-- Slot for delete -->
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                small
                icon
                @click="
                  () => {
                    toDelete = item._id;
                    dialog = true;
                  }
                "
                ><v-icon>delete</v-icon></v-btn
              >
            </template>
            <!-- / delete Slot -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialogs -->
    <v-dialog v-model="acceptDialog" persistent max-width="330">
      <v-card>
        <v-card-title>Voucher Number ကိုထည့်ပါ</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-text-field
            class="mt-4"
            label="Voucher နံပါတ်"
            outlined
            v-model="voucherNumber"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" dark @click="acceptDialog = false">
            မလုပ်တော့ပါ
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" dark @click="accept(selectedItem)">
            လဲပေးမည်
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import RedeemRequest from "../../api/model/RedeemRequest";
import RedeemRequestManager from "../../api/model/RedeemRequestManager";
import { ExportToCsv } from "export-to-csv";
export default {
  name: "RequestList",
  data: function () {
    return {
      page: 1,
      totalCount: null,
      numberOfPages: null,
      itemsPerPage: 10,
      loading: false,
      options: {},
      search: null,
      headers: [
        { text: "လဲသူအမည်", value: "user.username" },
        { text: "လဲပေးသူအမည်", value: "admin.name" },
        { text: "ဘောင်ချာနံပါတ်", value: "voucherNumber" },
        { text: "Golds", value: "points" },
        { text: "ပစ္စည်း", value: "item.title" },
        { text: "ဓာတ်ပုံ", value: "item.photo" },
        { text: "အမျိုးအစား", value: "type" },
        { text: "သတ်မှတ်ချက်", value: "status" },
      ],
      request: [],
      dialog: false,
      toDelete: null,
      acceptDialog: false,
      selectedItem: null,
    };
  },
  methods: {
    handleReport: function () {
      this.$router.push("/requests-report");
    },
    getExportData: function () {
      let exportData = [];
      this.request.map((data) => {
        exportData.push({
          Username: data.user.username,
          AdminName: data.admin.name,
          VoucherNumber: data.voucherNumber,
          Golds: data.points,
          Item: data.item.title,
          Type: data.type,
          Status: data.status,
        });
      });
      return exportData;
    },
    exportRequests: function () {
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: "Royal Tahlee Gold Club Requests",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Royal Tahlee Gold Club Requests",
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(this.getExportData());
    },
    readDataWithLimit: async function (page, itemsPerPage) {
      // Table is loading
      this.loading = true;
      try {
        // First get the server item length
        var result = await RedeemRequestManager.totalRequest(this.token);
        this.totalCount = result;
        console.log(this.totalCount);
        // Calculate skip and limit
        var skip;
        var limit;
        if (itemsPerPage == -1) {
          skip = 0;
          limit = this.totalCount;
        } else {
          skip = this.totalCount - itemsPerPage * page;

          if (skip < 0) {
            skip = 0;
            limit = itemsPerPage + skip;
          } else {
            limit = itemsPerPage;
          }
        }

        // Read data with limit
        result = await RedeemRequestManager.requestWithRange(
          skip,
          limit,
          this.token
        );
        this.request = result;
        console.log(this.request);
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot fetch request data !");
      }
    },
    searchItem: async function () {
      this.loading = true;
      if (!this.search) {
        // if search text is null reload with default
        this.readDataWithLimit(1, this.itemsPerPage);
      } else {
        try {
          this.request = await RedeemRequestManager.searchRequest(
            this.search,
            this.token
          );
          this.totalCount = this.request.length;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log("Error : ", err);
          this.$store.dispatch("MAKE_ERROR", "Unable to search !");
        }
      }
    },
    deleteItem: async function () {
      this.dialog = false;
      try {
        await RedeemRequest.delete(this.toDelete, this.token);
      } catch (error) {
        this.dialog = false;
        console.log("Error:", error);
        this.$store.dispatch("MAKE_ERROR", "Unable to delete");
      }
      this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
    },
    accept: async function (id) {
      let body = {
        admin: {
          _id: this.currentUser._id,
          name: this.currentUser.name,
          role: this.currentUser.role,
        },
        voucherNumber: this.voucherNumber,
      };

      try {
        await RedeemRequest.accept(id, this.token, body);
        this.acceptDialog = false;
      } catch (error) {
        console.log(error);
        alert("User ထံတွင် Golds မလုံလောက်ပါ");
      }
      this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
    },
    reject: async function (id) {
      try {
        await RedeemRequest.reject(id, this.token, "admin");
      } catch (error) {
        console.log("Error : ", error);
        this.$store.dispatch("MAKE_ERROR", "Unable to reject!");
      }
      this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
    },
  },
  watch: {
    // Do this function everytime the options change
    options: function (options) {
      this.readDataWithLimit(options.page, options.itemsPerPage);
    },
  },

  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
    currentUser: function () {
      return this.$store.state.user;
    },
  },
};
</script>
<style scoped>
.name:hover {
  cursor: pointer;
}
</style>
