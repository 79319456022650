<template>
  <div class="confirm-box">
    <v-dialog v-model="dialog" persistent max-width="330">
      <v-card>
        <v-card-title class="title"> အတည်ပြုပါ </v-card-title>
        <v-card-text>
          လက်ရှိဒေတာကို ဖျက်လိုပါသလား? ဖျက်ပြီးပြန်ယူ၍ ရနိုင်မည်မဟုတ်ပါ။
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey darken-1" text @click="() => $emit('closeDialog')"
            >မလုပ်တော့</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="()=> $emit('deleteItem')">ဆက်လုပ်မည်</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "ConfirmBox",
  props: {
    dialog: {
      type: Boolean,
      require: true,
    },
  },
};
</script>

<style>
</style>