import { GET, POST } from "../fetch";
const root = "point-record-manager";

export default {
  getRecordsWithUser: async function (id, token) {
    try {
      let response = await GET(`/${root}/get-records-with-user/${id}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  totalRecords: async function (token) {
    try {
      let response = await GET(`/${root}/total-records`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  recordsWithRange: async function (skip, limit, token) {
    let body = { skip, limit };
    try {
      let response = await POST(`/${root}/records-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithDate: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-data-with-date`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithWeek: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-data-with-week`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithMonth: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-data-with-month`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getDataWithDateRange: async function (startDate, endDate, token) {
    let body = { startDate, endDate };
    try {
      let response = await POST(`/${root}/get-data-with-date-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
};
