var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"point-records"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 dark--text text-caption px-5 mt-10",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalCount,"options":_vm.options,"items":_vm.records},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"9"}}),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"primary mx-2",attrs:{"depressed":"","rounded":"","block":"","dark":""},on:{"click":_vm.handleReport}},[_c('v-icon',[_vm._v("summarize")]),_vm._v(" Report ထုတ်မည် ")],1)],1)],1)]},proxy:true},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-2 primary--text font-weight-bold text-decoration-underline name",on:{"click":function($event){return _vm.$router.push(("/users/" + (item.userId)))}}},[_vm._v(" "+_vm._s(item.userId)+" ")])]}},{key:"item.boughtCategory",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.boughtCategory ? item.boughtCategory : "-"))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleDateString()))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm.getRecordType(item.type)))])]}},{key:"no-data",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"dark--text my-15",attrs:{"cols":"12","align":"center"}},[_c('v-icon',[_vm._v("info")]),_vm._v(" "+_vm._s("no_data")+" ")],1)],1)]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }