import { GET,PUT,DELETE } from "../fetch";
 const root = "item"

 export default {
    getInfo :async (itemId,token) => {
        try {
            let response = await GET(`/${root}/get-info/${itemId}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    updateInfo : async (itemId,item,token) => {
        try {
            let response = await PUT(`/${root}/update-info/${itemId}`,item,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    delete :async (itemId,token) => {
        try {
            let response = await DELETE(`/${root}/${itemId}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
 }