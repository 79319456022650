import { GET,POST } from "../fetch";
const root = "item-manager"

export default {

    createItem :async (item,token) => {
        try {
            let response = await POST(`/${root}/create-item`,item,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    getTotal : async (token) => {
        try {
            let response = await GET(`/${root}/get-total`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    itemWithRange : async (skip,limit,token )=> {
        let body = {skip,limit}
        try {
            let response = await POST(`/${root}/item-with-range`,body,token)
            return response 
        } catch (error) {
            throw new Error(error)
        }
    },
    
    searchItem :async (searchText,token) => {
        try {
            let response = await GET(`/${root}/search-item/${searchText}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
}