<template>
  <div class="admin">
    <Appbar />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Appbar from "@/components/Util/Appbar";
import Footer from "@/components/Util/Footer";

export default {
  name: "Admin",
  components: { Appbar, Footer },
};
</script>