<template>
    <div class="point-record-report">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card class="pa-10">
                        <v-row>
                            <v-col v-if="reportType !== 'စိတ်ကြိုက်ရွေးမည်'" cols="12" md="6">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            rounded
                                            filled
                                            v-model="date"
                                            label="လိုအပ်သောနေ့ကို ရွေးချယ်ပါ"
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            x-large
                                            text
                                            color="primary"
                                            @click="() => loadReportDatas(reportType)"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col v-else cols="12" md="6">
                                <v-row>
                                    <v-col cols="6">
                                        <v-menu
                                            ref="startDateMenu"
                                            v-model="startDateMenu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    rounded
                                                    filled
                                                    v-model="startDate"
                                                    label="အစရက်"
                                                    prepend-inner-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="startDate" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="startDateMenu = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    x-large
                                                    text
                                                    color="primary"
                                                    @click="loadReportDatasWithRange"
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-menu
                                            ref="endDateMenu"
                                            v-model="endDateMenu"
                                            :close-on-content-click="false"
                                            :return-value.sync="date"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    rounded
                                                    filled
                                                    v-model="endDate"
                                                    label="အဆုံးရက်"
                                                    prepend-inner-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="endDate" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="endDateMenu = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    x-large
                                                    text
                                                    color="primary"
                                                    @click="loadReportDatasWithRange"
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    rounded
                                    filled
                                    v-model="reportType"
                                    :items="['တစ်နေ့စာ', 'တစ်ပတ်စာ', 'တစ်လစာ', 'စိတ်ကြိုက်ရွေးမည်']"
                                    label="ထုတ်လိုသောပုံစံကို ရွေးချယ်ပါ"
                                    return-object
                                ></v-select>
                                <v-btn
                                    depressed
                                    x-large
                                    rounded
                                    block
                                    class="primary mx-2"
                                    dark
                                    @click="handleReport"
                                >
                                    <v-icon>file_download</v-icon>
                                    Excel ဖြင့်ဒေါင်းလုတ်လုပ်မည်
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                    <v-card-text>
                        <v-row class="my-15" v-if="loading">
                            <v-col cols="12" align="center">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-simple-table loading v-else>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">လက်ခံသူ</th>
                                        <th class="text-left">လုပ်ေဆာင်သူ</th>
                                        <th class="text-left">Golds</th>
                                        <th class="text-left">နေ့</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="record in records" :key="record._id">
                                        <td>{{ record.receiver.username }}</td>
                                        <td>{{ record.sender.username }}</td>
                                        <td>{{ record.points }}</td>
                                        <td>{{ new Date(record.createdAt).toLocaleString() }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import GiftcardRecordsManager from "@/api/model/GiftcardRecordsManager";
import { ExportToCsv } from "export-to-csv";
export default {
    name: "CnNewyearCardsReport",
    data: function () {
        return {
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            menu: false,
            records: [],
            loading: false,
            reportType: "တစ်နေ့စာ",
            startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            startDateMenu: false,
            endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            endDateMenu: false,
        };
    },
    methods: {
        handleReport: async function () {
            const options = {
                fieldSeparator: ",",
                quoteStrings: '"',
                decimalSeparator: ".",
                showLabels: true,
                showTitle: true,
                title:
                    this.reportType == "စိတ်ကြိုက်ရွေးမည်"
                        ? `Birthday Gift Report ${this.startDate} ~ ${this.endDate}`
                        : `Birthday Gift Report ${this.date} (${this.reportType})`,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename:
                    this.reportType == "စိတ်ကြိုက်ရွေးမည်"
                        ? `Birthday Gift Report ${this.startDate} ~ ${this.endDate}`
                        : `Birthday Gift Report ${this.date} (${this.reportType})`,
            };

            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(this.getExportData());
        },
        getExportData: function () {
            let exportData = [];
            this.records.map((data) => {
                exportData.push({
                    Receiver: data.receiver.username,
                    ActionedBy: data.sender.username,
                    Golds: data.points,
                    Date: data.createdAt,
                });
            });
            return exportData;
        },
        handleLoadData: async function () {
            this.loading = true;
            this.$refs.menu.save(this.date);
            try {
                let date = new Date(this.date).getDate();
                let month = new Date(this.date).getMonth();
                let year = new Date(this.date).getFullYear();

                let dateObj = { date, month, year };

                let data = await GiftcardRecordsManager.getNewyearDataWithDate(dateObj, this.token);
                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                alert("Something wrong, Please try again.");
            }
        },
        loadReportDatas: async function (type) {
            this.loading = true;

            if (this.$refs.menu) {
                this.$refs.menu.save(this.date);
            }

            try {
                let date = new Date(this.date).getDate();
                let month = new Date(this.date).getMonth();
                let year = new Date(this.date).getFullYear();

                let dateObj = { date, month, year };
                let data;

                switch (type) {
                    case "တစ်နေ့စာ":
                        data = await GiftcardRecordsManager.getNewyearDataWithDate(dateObj, this.token);
                        break;
                    case "တစ်ပတ်စာ":
                        data = await GiftcardRecordsManager.getNewyearDataWithWeek(dateObj, this.token);
                        break;
                    case "တစ်လစာ":
                        data = await GiftcardRecordsManager.getNewyearDataWithMonth(dateObj, this.token);
                        break;
                }

                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                alert("Something wrong, Please try again.");
            }
        },
        loadReportDatasWithRange: async function () {
            this.loading = true;

            if (this.$refs.startDateMenu) {
                this.$refs.startDateMenu.save(this.startDate);
            }

            if (this.$refs.endDateMenu) {
                this.$refs.endDateMenu.save(this.endDateMenu);
            }

            try {
                let startDate = {
                    date: new Date(this.startDate).getDate(),
                    month: new Date(this.startDate).getMonth(),
                    year: new Date(this.startDate).getFullYear(),
                };

                let endDate = {
                    date: new Date(this.endDate).getDate(),
                    month: new Date(this.endDate).getMonth(),
                    year: new Date(this.endDate).getFullYear(),
                };

                let data = await GiftcardRecordsManager.getNewyearDataWithDateRange(
                    startDate,
                    endDate,
                    this.token
                );
                this.records = data;

                this.loading = false;
            } catch (error) {
                this.loading = false;
                alert("Something wrong, Please try again.");
            }
        },
    },
    created: function () {
        this.loadReportDatas(this.reportType);
    },
    watch: {
        reportType: function (type) {
            this.loadReportDatas(type);
        },
    },
    computed: {
        token: function () {
            return this.$store.state.user.token;
        },
    },
};
</script>
