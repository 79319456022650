import { GET,POST } from "../fetch";
const root ="discount-manager"

export default {
    createDiscount : async (discount,token) => {
        try {
            let response = await POST(`/${root}/create-discount`,discount,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    totalDiscount : async (token) => {
        try {
            let response = await  GET(`/${root}/get-total`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },
    discountWithRange : async (skip,limit,token) =>{
        let body = {skip,limit}
        try {
            let response = await POST(`/${root}/discount-with-range`,body,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    },

    searchDiscount : async (searchText,token) =>{
        try {
            let response = await GET(`/${root}/search-discount/${searchText}`,token)
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
} 