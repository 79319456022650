import { GET, POST } from "../fetch";
const root = "system";

export default {
  searchAdmin: async function (searchText, token) {
    try {
      let response = await GET(`/${root}/search-admin/${searchText}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  totalAdmin: async function (token) {
    try {
      let response = await GET(`/${root}/total-admin`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  adminWithRange: async function (skip, limit, token) {
    try {
      let body = { skip, limit };
      let response = await POST(`/${root}/admin-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  searchUser: async function (searchText, token) {
    try {
      let response = await GET(`/${root}/search-user/${searchText}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  totalUser: async function (token) {
    try {
      let response = await GET(`/${root}/total-user`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  userWithRange: async function (skip, limit, token) {
    try {
      let body = { skip, limit };
      let response = await POST(`/${root}/user-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getUsersWithDate: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-users-with-date`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getUsersWithWeek: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-users-with-week`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getUsersWithMonth: async function (date, token) {
    let body = { date };
    try {
      let response = await POST(`/${root}/get-users-with-month`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getUsersWithDateRange: async function (startDate, endDate, token) {
    let body = { startDate, endDate };
    try {
      let response = await POST(`/${root}/get-users-with-date-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  searchVoucher: async function (text, token) {
    try {
      let response = await GET(
        `/${root}/search-with-student-name/${text}`,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getVoucherWithRange: async function (skip, limit, token) {
    try {
      let body = { skip, limit };
      let response = await POST(`/${root}/get-voucher-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getTotalVoucher: async function (token) {
    try {
      let response = await GET(`/${root}/get-total-voucher`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getStudentDataWithArray: async function (arr, token) {
    let body = { idArr: arr };
    try {
      let response = await POST(
        `/${root}/get-student-data-with-array`,
        body,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getStudentWithTimeRange: async function (startDate, endDate, token) {
    let body = { startDate, endDate };
    try {
      let response = await POST(
        `/${root}/get-student-with-time-range`,
        body,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getVoucherWithTimeRange: async function (startDate, endDate, token) {
    let body = { startDate, endDate };
    try {
      let response = await POST(`/${root}/get-voucher-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  insertGrade: async function (grade, token) {
    try {
      let response = await POST(`/${root}/insert-grade`, grade, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getAllGrades: async function () {
    try {
      let response = await GET(`/${root}/get-all-grade`);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  searchBook: async function (text, token) {
    try {
      let response = await GET(`/${root}/search-book/${text}`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  getUpcomingBatchWithGrade: async function (grade, token) {
    try {
      let response = await GET(
        `/${root}/get-upcoming-batch-with-grade/${grade}`,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getRunningBatchWithGrade: async function (grade, token) {
    try {
      let response = await GET(
        `/${root}/get-running-batch-with-grade/${grade}`,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getTotalNewStudent: async function (token) {
    try {
      let response = await GET(`/${root}/total-new-student`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getNewStudentWithRange: async function (skip, limit, token) {
    try {
      let body = { skip, limit };
      let response = await POST(`/${root}/new-student-with-range`, body, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  searchNewStudent: async function (searchText, token) {
    try {
      let response = await GET(
        `/${root}/search-new-student/${searchText}`,
        token
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  createSetting: async function (setting, token) {
    try {
      let response = await POST(`/${root}/create-setting`, setting, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getAllSetting: async function (token) {
    try {
      let response = await GET(`/${root}/get-all-settings`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },

  getBirthdayUsers: async function (token) {
    try {
      let response = await GET(`/${root}/birthday-users`, token);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};
