<template>
	<div class="send-notification">
		<v-container>
			<v-row>
				<v-col cols="12">
					<v-card class="mt-8">
						<v-card-title class="dark--text subtitle-1 font-weight-bold">
							<v-btn
								depressed
								rounded
								class="secondary mx-2"
								dark
								@click="() => $router.go(-1)"
							>
								<v-icon>keyboard_arrow_left</v-icon>
							</v-btn>
							Notification ပို့ရန်
							<v-spacer></v-spacer>
							<v-btn
								rounded
								depressed
								class="primary"
								@click="startNotificationInterval"
								large
								dark
								v-if="!loading"
							>
								<v-icon>save</v-icon>
								ပို့မည်
							</v-btn>
							<v-btn
								rounded
								depressed
								class="primary"
								@click="stopNotificationInterval"
								large
								dark
								v-else
							>
								<v-icon>stop</v-icon>
								ရပ်မည်
							</v-btn>
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12" mt="4">
										<p class="dark--text body-2 font-weight-bold">
											ခေါင်းစဥ် -
										</p>
										<v-text-field
											solo
											background-color="grey lighten-2"
											flat
											rounded
											class="dark--text"
											v-model="title"
											type="text"
											placeholder="Notification ခေါင်းစဥ်"
										>
										</v-text-field>
									</v-col>
									<v-col cols="12" mt="4">
										<p class="dark--text body-2 font-weight-bold">စာသား -</p>
										<v-text-field
											solo
											background-color="grey lighten-2"
											flat
											rounded
											class="dark--text"
											v-model="body"
											type="text"
											placeholder="Notification စာသား"
										>
										</v-text-field>
									</v-col>
									<v-divider></v-divider>
									<v-col cols="12" v-if="loading">
										<v-progress-linear :buffer-value="percentage" height="25" striped background-color="primary" background-opacity="0.5">
											<strong>{{ Math.ceil(percentage) }}%</strong>
										</v-progress-linear>
										<p class="caption">
											Sent to {{ currentSkip }} / {{ totalUsers }}
										</p>
									</v-col>
									<v-col cols="12" v-else-if="!loading && percentage == 100">
										<v-alert type="success" border="left" dismissible>
											အသုံးပြုသူ {{ totalUsers }} ယောက်သို့ notification
											ပို့ခြင်းအောင်မြင်ပါသည်
										</v-alert>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import System from "../../api/model/System"
import ExpoNotification from "../../api/model/ExpoNotification"
export default {
	name: "SendNotification",
	data: function () {
		return {
			loading: false,
			title: null,
			body: null,
			percentage: 0,
			totalUsers: null,
			currentSkip: 0,
			userPerSend: 20,
			notificationInterval: null,
		}
	},
	methods: {
		stopNotificationInterval: async function () {
			clearInterval(this.notificationInterval)
            this.loading = false
		},
		sendNotification: async function (skip) {
			let users = await System.userWithRange(skip, this.userPerSend, this.token)

			let tokens = []
			for (let i = 0; i < users.length; i++) {
				if (users[i].expoTokens) {
					tokens.push(...users[i].expoTokens)
				}
			}

			let notification = {
				tokens,
				title: this.title,
				body: this.body,
			}

			if (tokens.length > 0) {
				try {
					await ExpoNotification.sendNotification(notification, this.token)
				} catch (error) {
					console.log()
				}
			}
		},
		startNotificationInterval: async function () {
			this.loading = true
			this.totalUsers = await System.totalUser(this.token)
			let self = this

			self.currentSkip = 0
			self.percentage = 0

			this.notificationInterval = setInterval(async () => {
				await self.sendNotification(self.currentSkip)

				if (self.currentSkip < self.totalUsers) {
					self.currentSkip = self.currentSkip + self.userPerSend
				} else {
					self.currentSkip = self.totalUsers
					self.loading = false
				}
				self.percentage = Math.floor((self.currentSkip / self.totalUsers) * 100)
			}, 3000)
		},
	},
	computed: {
		token: function () {
			return this.$store.state.user.token
		},
	},
	watch: {
		currentSkip: function (currentSkip) {
			if (currentSkip > this.totalUsers) {
				clearInterval(this.notificationInterval)
				this.loading = false
			}
		},
	},
}
</script>
