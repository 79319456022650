<template>
  <div class="News">
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- List of students -->
          <v-data-table
            :page="page"
            :pageCount="numberOfPages"
            :search="search"
            :loading="loading"
            :headers="headers"
            :items-per-page="itemsPerPage"
            :server-items-length="totalCount"
            :options.sync="options"
            :items="news"
            class="elevation-1 dark--text text-caption px-5 mt-10"
          >
            <!-- Top of table -->
            <template v-slot:top>
              <v-row>
                <v-col cols="6">
                  <!-- Search Box -->
                  <form v-on:submit.prevent="searchItem">
                    <v-text-field
                      v-model="search"
                      placeholder="Search"
                      class="mx-4 dark--text text-body-2 px-5"
                      clearable
                      solo
                      filled
                      dense
                      flat
                      prepend-icon="refresh"
                      append-icon="search"
                      background-color="grey lighten-3"
                      color="dark"
                      @click:append="searchItem"
                      @click:prepend="() => readDataWithLimit(1, itemsPerPage)"
                      @click:clear="readDataWithLimit(1, itemsPerPage)"
                    >
                    </v-text-field>
                  </form>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    rounded
                    depressed
                    class="primary"
                    link
                    :to="'/news-add'"
                    large
                  >
                    <v-icon>add</v-icon>
                    အသစ်ဖွင့်မည်
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <!-- End of top -->
            <template v-slot:no-data>
              <v-row>
                <v-col cols="12" align="center" class="dark--text my-15">
                  <v-icon>info</v-icon> {{ "no_data" }}
                </v-col>
              </v-row>
            </template>

            <!-- Slot for name -->
            <template v-slot:[`item.title`]="{ item }">
              <p
                @click="$router.push(`/news/${item._id}`)"
                class="body-2 blue--text mt-3 text-decoration-underline name"
              >
                {{ item.title }}
              </p>
            </template>

            <!-- Slot for description -->
            <template v-slot:[`item.description`]="{ item }">
              <p>
                {{
                  item.description
                    .split("")
                    .splice(0, 50)
                    .join("")
                    .concat(" ...")
                }}
              </p>
            </template>

            <!-- Slot for image -->
            <template v-slot:[`item.photo`]="{ item }">
              <v-img
                :src="item.photo ? item.photo : require('@/assets/LogoSm.jpg')"
                width="60"
                height="60"
                class="my-2"
              ></v-img>
            </template>
            <!-- / Slot for image -->
            <!-- slot for edit -->
            <template v-slot:[`item.edit`]="{ item }">
              <v-btn small icon @click="editItem(item._id)"
                ><v-icon>edit</v-icon></v-btn
              >
            </template>
            <!-- slot for edit item -->

            <!-- Slot for delete -->
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                small
                icon
                @click="
                  () => {
                    toDelete = item._id;
                    dialog = true;
                  }
                "
                ><v-icon>delete</v-icon></v-btn
              >
            </template>
            <!-- / delete Slot -->
          </v-data-table>
          <confirm-box
            :dialog="dialog"
            @closeDialog="() => (dialog = false)"
            @deleteItem="deleteItem"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import News from "../../api/model/News";
import NewsManager from "../../api/model/NewsManager";
import ConfirmBox from "../../components/Util/ConfrimBox.vue";
export default {
  name: "NewsList",
  components: { ConfirmBox },
  data: function () {
    return {
      page: 1,
      totalCount: null,
      numberOfPages: null,
      itemsPerPage: 10,
      loading: false,
      options: {},
      search: null,
      headers: [
        { text: "ခေါင်းစဥ်", value: "title" },
        { text: "ဓာတ်ပုံ", value: "photo" },
        { text: "ဖော်ပြချက်", value: "description" },
        { text: "ဖျက်မည်", value: "delete" },
      ],
      news: [],
      dialog: false,
      toDelete: null,
    };
  },
  methods: {
    readDataWithLimit: async function (page, itemsPerPage) {
      // Table is loading
      this.loading = true;
      try {
        // First get the server item length
        var result = await NewsManager.totalNews(this.token);
        this.totalCount = result;
        // Calculate skip and limit
        var skip = this.totalCount - itemsPerPage * page;
        var limit;
        if (skip < 0) {
          skip = 0;
          limit = itemsPerPage + skip;
        } else {
          limit = itemsPerPage;
        }

        // Read data with limit
        result = await NewsManager.newsWithRange(skip, limit, this.token);
        this.news = result;
        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.log("Error : ", err);
        this.$store.dispatch("MAKE_ERROR", "Cannot fetch news data !");
      }
    },
    searchItem: async function () {
      this.loading = true;
      if (!this.search) {
        // if search text is null reload with default
        this.readDataWithLimit(1, this.itemsPerPage);
      } else {
        try {
          this.news = await NewsManager.searchNews(this.search, this.token);
          this.totalCount = this.news.length;
          this.loading = false;
        } catch (err) {
          this.loading = false;
          console.log("Error : ", err);
          this.$store.dispatch("MAKE_ERROR", "Unable to search !");
        }
      }
    },
    deleteItem: async function () {
      this.dialog = false;
      try {
        await News.delete(this.toDelete, this.token);
      } catch (error) {
        this.dialog = false;
        console.log("Error:", error);
        this.$store.dispatch("MAKE_ERROR", "Unable to delete");
      }
      this.readDataWithLimit(this.options.page, this.options.itemsPerPage);
    },
    editItem: function (newsId) {
      this.$router.push("/news" + newsId);
    },
  },
  watch: {
    // Do this function everytime the options change
    options: function (options) {
      this.readDataWithLimit(options.page, options.itemsPerPage);
    },
  },

  computed: {
    token: function () {
      return this.$store.state.user.token;
    },
  },
};
</script>
<style scoped>
.name:hover {
  cursor: pointer;
}
</style>
