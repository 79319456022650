<template>
  <div class="auth">
    <Appbar />
    <router-view />
    <Footer />
  </div>
</template>
<script>
import Appbar from "@/components/Auth/Appbar";
import Footer from "@/components/Util/Footer";
export default {
  name: "Auth",
  components: { Appbar, Footer },
};
</script>